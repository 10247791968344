<template>
	<div class="pt-3 pl-5 pr-5">
		<div v-if="isLoading">
			<div class="row w-full justify-between flex" style="align-items: center">
			 <h1 class="title">{{ label }}</h1>
				<input type="file" accept=".xls,.xlsx" class="hidden" ref="file" @change="fileReader($event)" />
				<div class="flex flex-row-reverse">
					<button class="flex" @click="filterOpen" :class="!columnObject ? 'filterButton' : 'filterButtondisabled pointer-events-none'">
						Filters
						<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
					</button>
					<div class="flex flex-row-reverse mr-2">
						<button class="btnprimary btns flex justify-center items-center" @click="showBlock = !showBlock">Add <img src="@/assets/Collapse.svg" alt="" class="ml-2" /></button>
						<ul class="card shadow-sm z-50 absolute cursor-pointer top-24" v-if="showBlock && showDropdown">
							<li class="px-4 py-2 cursor-pointer border-b h-7 border-lightgrey hover:bg-lightgrey text-sm flex items-center">
								<div @click="openFileModal">Import</div>
							</li>
							<li class="px-4 py-2 cursor-pointer border-b h-7 border-lightgrey hover:bg-lightgrey text-sm flex items-center">
								<div @click="addControls">Create</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<tableData :tableRowData="tableRow" :headersData="headers"></tableData>

			<popup v-if="showModal" :popUp="showModal">
				<template v-slot:header>
					<div class="flex items-center justify-between py-4 px-2 rounded">
						<h1 class="pl-2">Internal Control</h1>
						<button class="float-right text-3xl" @click="onCancelbtn('close')">
							<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
						</button>
					</div>
				</template>
				<div>
					<div class="statuscontainer popupbodycontainer">
						<div class="formcontainer flex flex-col">
							<fieldset>
								<div class="row flex flex-row w-full upperspacing">
									<div class="w-full">
										<label class="controllabel">Title</label>
										<input type="text" class="inputboxstyling" placeholder="Title" v-model="controlObject.controlTitle" />
										<!-- :class="{
												'is-invalid': v$.controlObject.controlTitle.$error
											}"
										<div v-if="v$.controlObject.controlTitle.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlTitle.required.$invalid">Required</div>
										</div> -->
									</div>
								</div>
								<div class="row flex flex-row w-full upperspacing">
									<div class="w-6/12 col-md-6">
										<label class="controllabel">Number</label>
										<input
											type="text"
											class="inputboxstyling"
											v-model="controlObject.controlNumber"
											placeholder="Number"
											:class="{
												'is-invalid': v$.controlObject.controlNumber.$error
											}"
										/>
										<!-- @change="controlNumberUnique(controlObject)" -->
										<div v-if="v$.controlObject.controlNumber.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlNumber.required.$invalid">Required</div>
										</div>
									</div>

									<div class="w-6/12 col-md-6 leftspacing">
										<label class="controllabel">Frequency</label>
										<select class="inputboxstyling" :class="{ 'is-invalid': v$.controlObject.controlFrequency.$error }" v-model="controlObject.controlFrequency">
											<option value="" selected disabled hidden>Select</option>
											<option v-for="(value, index) in controlFrequency" :key="index" :value="value.id">{{ value.label }}</option>
										</select>
										<div v-if="v$.controlObject.controlFrequency.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlFrequency.required.$invalid">Required</div>
										</div>
									</div>
								</div>
								<div class="row flex flex-row w-full upperspacing">
									<div class="w-6/12 col-md-6">
										<label class="controllabel">Type</label>
										<input
											type="text-area"
											class="inputboxstyling"
											placeholder="Type"
											v-model="controlObject.controlType"
											:class="{
												'is-invalid': v$.controlObject.controlType.$error
											}"
										/>
										<!-- <select class="inputboxstyling" :class="{ 'is-invalid': v$.controlObject.controlType.$error }" v-model="controlObject.controlType">
											<option value="" selected disabled hidden>Select</option>
											<option v-for="(value, index) in controlTypeArray" :key="index" :value="value.id">{{ value.label }}</option>
										</select> -->
										<div v-if="v$.controlObject.controlType.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlType.required.$invalid">Required</div>
										</div>
									</div>
									<div class="w-6/12 col-md-6 leftspacing">
										<label class="controllabel">Priority</label>
										<select class="inputboxstyling" :class="{ 'is-invalid': v$.controlObject.controlPriority.$error }" v-model="controlObject.controlPriority">
											<option value="" selected disabled hidden>Select</option>
											<option v-for="(value, index) in priorityArray" :key="index" :value="value.id">{{ value.label }}</option>
										</select>
										<div v-if="v$.controlObject.controlPriority.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlPriority.required.$invalid">Required</div>
										</div>
									</div>
								</div>
								<div class="row flex flex-row w-full upperspacing">
									<div class="w-6/12 col-md-6">
										<label class="controllabel">Family</label>
										<input
											type="text"
											class="inputboxstyling"
											placeholder="Family"
											v-model="controlObject.controlFamily"
											:class="{
												'is-invalid': v$.controlObject.controlFamily.$error
											}"
										/>
										<div v-if="v$.controlObject.controlFamily.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlFamily.required.$invalid">Required</div>
										</div>
									</div>
									<div class="w-6/12 col-md-6 leftspacing">
										<label class="controllabel">Class</label>
										<input
											type="text"
											class="inputboxstyling"
											placeholder="Class"
											v-model="controlObject.controlClass"
											:class="{
												'is-invalid': v$.controlObject.controlClass.$error
											}"
										/>
										<div v-if="v$.controlObject.controlClass.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlClass.required.$invalid">Required</div>
										</div>
									</div>
								</div>
								<div class="row flex flex-row w-full upperspacing">
									<div class="w-full">
										<label class="controllabel">Audit Methodology</label>
										<textarea type="text-area" class="inputboxstyling" placeholder="Audit Methodology" v-model="controlObject.controlAuditMethodology"> </textarea>
									</div>
								</div>
								<div class="row flex flex-row w-full upperspacing">
									<div class="w-full">
										<label class="controllabel">Audit Success Criteria</label>
										<textarea type="text-area" class="inputboxstyling" placeholder=" Audit Success Criteria" v-model="controlObject.controlAuditSuccessCriteria"> </textarea>
									</div>
								</div>
								<div class="row flex flex-row w-full upperspacing">
									<div class="w-full">
										<label class="controllabel">Maintenance Task</label>
										<textarea type="text-area" class="inputboxstyling" placeholder="Maintenance Task" v-model="controlObject.controlMaintenanceTask"> </textarea>
									</div>
								</div>
								<div class="row flex flex-row w-full upperspacing">
									<div class="w-full">
										<label class="controllabel">Policies</label>
										<textarea type="text-area" class="inputboxstyling" placeholder="Policies" v-model="controlObject.controlPolicies"> </textarea>
									</div>
								</div>
								<div class="row flex flex-row w-full upperspacing">
									<div class="w-full">
										<label class="controllabel">Guidance</label>
										<textarea type="text-area" class="inputboxstyling" placeholder="Control Guidance" v-model="controlObject.controlGuidance"> </textarea>
									</div>
								</div>
								<div class="w-full col-md-6 upperspacing">
									<label class="controllabel">Frameworks</label>
									<select class="inputboxstyling" @change="getFrameworkControls(controlObject.controlOptionFrameWork)" v-model="controlObject.controlOptionFrameWork">
										<option value="" selected disabled hidden>Select</option>
										<option v-for="data in frameWorksList" :key="data.label" :value="data.label">{{ data.label }}</option>
									</select>
									<!-- <div v-if="v$.controlObject.controlOptionFrameWork.$error" class="text-red-500">
										<div class="error-text" v-if="v$.controlObject.controlOptionFrameWork.required.$invalid">Required</div>
									</div> -->
								</div>
								<div class="w-full col-md-6 upperspacing">
									<label class="controllabel">Controls</label>
									<select class="inputboxstyling" v-model="controlObject.frameworkControls">
										<option value="" selected disabled hidden>Select</option>
										<option v-for="data in frameworkControls" :key="data.control_number" :value="data.control_number">{{ data.control_number }}</option>
									</select>
									<!-- <div v-if="v$.controlObject.frameworkControls.$error" class="text-red-500">
										<div class="error-text" v-if="v$.controlObject.frameworkControls.required.$invalid">Required</div>
									</div> -->
								</div>
							</fieldset>
						</div>
						<div class="buttonposition flex justify-center items-center mt-4 mx-0">
							<button class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn('cancel')">Cancel</button>
							<button class="btnprimary" v-if="!controlObject._id" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="saveCustomControl(controlObject)">Save</button>
							<button class="btnprimary" v-else :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="updateControl(controlObject)">Update</button>
						</div>
					</div>
				</div>
			</popup>
		</div>
		<!-- <loader v-if="showLoader" /> -->
	</div>
</template>

<style scoped>
.borderRed {
	border: 1px solid red !important;
}
::-webkit-scrollbar {
	width: 3px !important;
}
</style>
<script lang="ts">
import _ from 'lodash';
import * as XLSX from 'xlsx';
// import loader from '../../components/loader.vue';
import { defineComponent } from 'vue';
import { emitter, toast } from '../../main';
import { helpers } from '@vuelidate/validators';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import popup from '@/components/popup.vue';
import tableData from '@/components/tableData.vue';
// import Multiselect from '@vueform/multiselect';
export default defineComponent({
	data(): any {
		return {
			headers:['_id','number','title','family','class','priority'],

			v$: useVuelidate(),
			isLoading: false,
			showLoader: false,
			jsonArray:[],

			priority: ['Critical', 'High', 'Moderate', 'Low'],
			priorityArray: [
				{ label: 'Very Low', id: 1 },
				{ label: 'Low', id: 2 },
				{ label: 'Moderate', id: 3 },
				{ label: 'High', id: 4 },
				{ label: 'Critical', id: 5 }
			],
			classes: [],
			tableRow: [],
			nonTableRow: [],
			editObject: {},
			framework: [],
			showModal: false,
			showBlock: false,
			showDropdown: false,

			label: '',

			controlSet: '',
			frameworkArray: [],
			columnObject: false,

			showFilters: false,
			controlTypeArray: [
				{ label: 'Preventive', id: 1 },
				{ label: 'Detective', id: 2 },
				{ label: 'Corrective', id: 3 },
				{ label: 'Deterrent', id: 4 },
				{ label: 'Directive', id: 5 },
				{ label: 'Recovery', id: 6 },
				{ label: 'Compensating', id: 7 }
			],
			controlFrequency: [
				{ label: 'Transactional', id: 1 },
				{ label: 'Daily', id: 2 },
				{ label: 'Weekly', id: 3 },
				{ label: 'Monthly', id: 4 },
				{ label: 'Quarterly', id: 5 },
				{ label: 'Semi Annualy', id: 6 },
				{ label: 'Annualy', id: 7 },
				{ label: 'Ad-Hoc', id: 8 }
			],

			controlsList: [],
			dummyControlList: [],
			frameworkMappedArray: [],
			groupedFramewoks: [],
			frameWorksList: [],
			frameworkControls: [],
			controlObject: {
				controlFrameWork: '',
				controlNumber: '',
				controlPriority: '',
				controlTitle: '',
				controlClass: '',
				controlFamily: '',
				controlGuidance: '',
				controlAuditMethodology: '',
				controlAuditSuccessCriteria: '',
				controlMaintenanceTask: '',
				controlPolicies: '',
				controlType: '',
				controlFrequency: '',
				controlOptionFrameWork: '',
				frameworkControls: ''
			},
			existingControlNumbers: new Set<string>(),
			mandatoryKeys: ['title', 'controlAuditMethodology ', 'controlAuditSuccessCriteria', 'controlMaintenanceTask', 'controlPolicies', 'number', 'family', 'priority', 'class', 'controlGuidance', 'type'],
			fullKeys: ['controlNumber', 'controlTitle', 'controlGuidance', 'controlClass', 'controlFamily', 'controlType', 'controlPriority', 'controlFrameWork']
		};
	},
	validations: {
		controlObject: {
			// controlFrameWork: { required },
			// controlMapFrameWork: { required },
			controlNumber: { required },
			controlPriority: { required },
			// controlTitle: { required },
			controlClass: { required },
			controlFamily: { required },
			// controlObjective: { required },
			// controlGuidance: { required },
			// controlAuditMethodology: { required },
			// controlAuditSuccessCriteria: { required },
			// controlMaintenanceTask: { required },
			// controlPolicies: { required },
			controlType: { required },
			controlFrequency: { required }
		}
	},
	components: {
		popup,
		tableData,
		// Multiselect,
		// loader
	},
	async mounted() {
		await this.orgFetchControlsInfo();
		await this.getAllControls();
		this.label = 'Internal Controls';
		emitter.on('internalControls', (data: any) => {
			let dataModified = this.controlsList.find((item: any) => {
				return item._id == data.entityData._id;
			});
			this.showEditPopUp(dataModified);
		});
		emitter.on('*', (type, booleanValue) => {
			if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
				this.showBlock == true ? ((this.showBlock = false), (this.showDropdown = false)) : '';
			} else {
				if (this.showBlock == true) {
					if (this.showDropdown == false) {
						this.showDropdown = true;
					} else {
						this.showDropdown = false;
						this.showBlock = false;
					}
				} else {
					this.showDropdown = false;
				}
			}
		});
	},
	computed: {
		disableStatus: function (): any {
			let disableStatusCondition;
			if (this.controlObject._id) {
				if (JSON.stringify(this.controlObject) == JSON.stringify(this.editObject)) {
					disableStatusCondition = false;
				} else {
					disableStatusCondition = true;
				}
			} else {
				let val: any = [];
				Object.values(this.controlObject).forEach((value: any) => {
					val.push(value);
				});
				for (let n of val) {
					if (n != '') {
						disableStatusCondition = true;
					}
				}
			}
			return disableStatusCondition;
		}
	},
	methods: {
		addControls() {
			// (this.controlObject.controlFrameWork = ''),
			// 	(this.controlObject.controlMapFrameWork = ''),
			// 	(this.controlObject.controlNumber = ''),
			// 	(this.controlObject.controlPriority = ''),
			// 	(this.controlObject.controlTitle = ''),
			// 	(this.controlObject.controlClass = ''),
			// 	(this.controlObject.controlFamily = ''),
			// 	(this.controlObject.controlGuidance = ''),
			// 	(this.controlObject.controlAuditMethodology = ''),
			// 	(this.controlObject.controlAuditSuccessCriteria = ''),
			// 	(this.controlObject.controlMaintenanceTask = ''),
			// 	(this.controlObject.controlPolicies = ''),
			// 	(this.controlObject.controlType = ''),
			// 	(this.controlObject.controlFrequency = '');
			// 	this.controlObject.controlOptionFrameWork='';
			// 	this.controlObject.frameworkControls=[];

			this.v$.$reset();
			// this.editObject = {};
			Object.keys(this.controlObject).forEach((prop) => {
				this.controlObject[prop] = '';
			});
			Object.keys(this.editObject).forEach((prop) => {
				this.editObject[prop] = '';
			});
			// delete this.controlObject._id;
			this.showModal = true;
		},
		groupByFrameworks(arr: any[]) {
			return arr.reduce((group: any, product: any) => {
				const { controlFrameWork } = product;
				group[controlFrameWork] = group[controlFrameWork] ?? [];
				group[controlFrameWork].push(product);
				return group;
			}, {});
		},
		getFrameworkControls(name:any){
			this.frameworkControls=[];
			let array=Object.values(this.jsonArray)
				array.forEach((x:any)=>{
					x.forEach((y:any)=>{
						if(y.controlFrameWork == name){
							this.frameworkControls.push({frameWorkName:name, control_number:y.controlNumber, control_title:y.controlTitle});
						}
					})
				})

		},
		async getAllControls(frameName: any) {
			emitter.emit('visible', false);
			this.showFilters = false;
			try {
				this.isLoading = false;
				const result = await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls`);
				this.isLoading = true;
				this.columnObject = false;
				this.rowData = result.data;
				console.log('Result Data is ', this.rowData);
				//  jsonArray: any = [];
				let arr: any = [];
				this.rowData.forEach((item: any) => {
					if (item.controlFrameWork && item.controlNumber && Object.keys(item).includes('controlFrameWork') && Object.keys(item).includes('controlNumber') && !Object.keys(item).includes('isInternalControl')) {
						arr.push(item);
						this.jsonArray = this.groupByFrameworks(arr);
					}
				});
				console.log('jsonArray', this.jsonArray);
				let array=Object.values(this.jsonArray)
				// array.forEach((data:any)=>{
				// 	data.forEach(())
				// })
				
				let displayFrameName = '';	
				Object.keys(this.jsonArray).forEach((key, index) => {
					console.log('groupByFrameworkkeyyyyyyys', key);
					if (frameName === index) {
						displayFrameName = key;
					}
					this.frameWorksList.push({ value: index, label: key });
				});
				// Object.values(jsonArray).forEach((value, index) => {
				// 	console.log("groupByFrameworvalueeeees", value)
				// 	this.frameworkControls = value
				// 	// if (frameName === index) {
				// 	// 	displayFrameName = key;
				// 	// }
				// 	// this.frameWorksList.push({value: index, displayName: key });
				// });

				this.frameworkControls = [];
				this.frameworkControls = arr
					.filter((control: any) => control.controlFrameWork && control.controlFrameWork === displayFrameName)
					.map(
						(control: any) => ({ value: control._id, label: control.controlNumber + control.controlTitle })
						// .push({value: control._id, label: control.controlNumber + control.controlTitle})
					);
				console.log('frameWorksList', this.frameWorksList);
				console.log('frameworkControls', this.frameworkControls);
			} catch (e) {
				this.isLoading = false;
				toast.error(`Something went wrong`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		mappedFrameWork(data: any) {
			this.controlObject.controlMapFrameWork = [];
			this.frameworkMappedArray = this.groupBy(this.controlsList, 'frameworkgroupBy');
			let frameArr: any = [];
			Object.keys(this.frameworkMappedArray).forEach((frame: any) => {
				if (frame === data.controlFrameWork) {
					frameArr = Object.values(this.frameworkMappedArray[frame]);
				}
			});
			this.groupedFramewoks = [];
			frameArr.forEach((control: any) => {
				this.groupedFramewoks.push({ value: control._id, label: control.controlNumber });
			});
		},
		showEditPopUp(data: any) {
			// this.mappedFrameWork(data);
			this.v$.$reset();
			this.controlObject = {...data};
			this.editObject = { ...this.controlObject };
			this.showModal = true;
		},
		onCancelbtn(status: any) {
			this.v$.$reset();
			this.showBlock = false;
			if (status === 'close') {
				Object.keys(this.controlObject).forEach((prop: any) => {
					this.controlObject[prop] = '';
				});
				this.showModal = false;
			} else if (status == 'cancel') {
				this.controlObject = { ...this.editObject };
			}
		},
		filterOpen() {
			if (this.columnObject == false) {
				this.showFilters = !this.showFilters;
				emitter.emit('visible', this.showFilters);
			}
		},
		saveCustomControl(controlObject: any) {
			this.v$.$touch();
			console.log('dummyControlList', this.dummyControlList);
			if (!this.v$.controlObject.$invalid) {
				let uniqueControlNumber: any;
				uniqueControlNumber = this.dummyControlList.some((item: any) => item.controlNumber === controlObject.controlNumber);
				console.log('let uniqueControlNumber', uniqueControlNumber);
				let arr = []
				let frameWork = controlObject.controlOptionFrameWork
				let controls = controlObject.frameworkControls
				delete controlObject.frameworkControls
				delete controlObject.controlOptionFrameWork
				let obj = {
					framework: frameWork,
					controls: [controls]
				}
				console.log("obj", obj)
				arr.push(obj)
				controlObject.frameworks = arr
				if (uniqueControlNumber == false) {
					this.showModal = false;
					let payload;
					let framework = 'InternalControls';
					payload = {
						controlFrameWork: framework,
						controlsList: [{ ...controlObject, isInternalControl: true }]
					};
					console.log("payload...",payload);

					this.addControlSource([payload]);
				} else {
					this.showModal = false;
					toast.error(`Control Number should be unique`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
			}
		},
		controlNumberUnique(controlObject: any) {
			let uniqueControlNumber: any;
			uniqueControlNumber = this.dummyControlList.some((item: any) => item.controlNumber === controlObject.controlNumber);
			console.log('uniqueControlNumber == false', uniqueControlNumber);
			if (uniqueControlNumber) {
				toast.error(`Control Number should be unique`, {
					timeout: 2000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		async updateControl(controlObject: any) {
			this.v$.$touch();
			if (!this.v$.$invalid) {
				let uniqueControlNumber = this.dummyControlList.some((item: any) => item.controlNumber === controlObject.controlNumber);

				if (uniqueControlNumber) {
					this.showModal = false;
					let objId = controlObject._id;
					// delete controlObject._id;
					try {
						await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controlset/update/${objId}`, _.omit(controlObject, ['_id'])).then((result: any) => {
							toast.info(`Updated Successfully`, {
								timeout: 1000,
								closeOnClick: true,
								closeButton: 'button',
								icon: true
							});
						});
					} catch (e) {
						toast.error(`Something went wrong`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
					await this.orgFetchControlsInfo();
				} else {
					this.showModal = false;
					toast.error(`Control Number should be unique`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
			}
		},
		makeTable() {
			this.tableRow = [];
			if (this.controlsList.length > 0) {
				this.controlsList.forEach((item: any) => {
					// if (item.isInternalControl) {
					this.nonTableRow.push(item);
					this.tableRow.push({ _id: item._id, number: item.controlNumber, title: item.controlTitle, family: item.controlFamily, class: item.controlClass, priority: Number(item.controlPriority) ? parseInt(item.controlPriority) : item.controlPriority });
					// }
					if (item.controlFrameWork) {
						this.frameworkArray.push(item.controlFrameWork);
					}
				});
			} else {
				this.tableRow.push({ _id: '', number: '', title: '', family: '', class: '', priority: '' });
			}
			for (const row of this.tableRow) {
				for (const value of Object.values(row)) {
					if (value == '') {
						this.columnObject = true;
					} else {
						this.columnObject = false;
					}
				}
			}
			if (this.frameworkArray) {
				this.framework = new Set(this.frameworkArray);
			} else {
				let frameArt = ['NIST', 'IST', 'HIPPA'];
				this.framework = new Set(frameArt);
			}
		},

		async orgFetchControlsInfo() {
			this.controlsList = [];
			this.showLoader = true;
			this.isLoading = false;
			await this.$http
				.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls?frameWork=InternalControls`)
				.then((result: { data: any }) => {
					this.isLoading = true;
					this.showLoader = false;
					result.data.forEach((item: any) => {
						if (item.isInternalControl) {
							this.controlsList.push(item);
						}
					});
					this.dummyControlList = [...this.controlsList];
				})
				.catch((error: any) => {
					this.isLoading = false;
					(this.showLoader = false),
						toast.error(`Save failed with ${error}`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
				});
			this.makeTable();
		},
		groupBy(arr: any[], key: string | number) {
			if (key == 'frameworkgroupBy' || key == 'controlFrameWork') {
				return arr.reduce((group: any, product: any) => {
					const { controlFrameWork } = product;
					group[controlFrameWork] = group[controlFrameWork] ?? [];
					group[controlFrameWork].push(product);
					return group;
				}, {});
			} else {
				return arr.reduce((storage: { [x: string]: any[] }, item: { [x: string]: any }) => {
					if (_.every(this.mandatoryKeys, _.partial(_.has, item))) {
						var group = item[key];
						storage[group] = storage[group] || [];
						storage[group].push(item);
					}
					return storage;
				}, {});
			}
		},
		async addControlSource(payload: any, action: any) {
			try {
				await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controlset/create`, payload).then(async (result: any) => {
					await this.orgFetchControlsInfo();
					if (action != 'toast') {
						toast.info(`Saved Successfully`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
				});
			} catch (e) {
				toast.error(`Something went wrong`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		fileReader(oEvent: any): any {
			var oFile = oEvent.target.files[0];
			var reader = new FileReader();
			reader.onload = async (e: any) => {
				let data: any = e.target.result;
				data = new Uint8Array(data);
				var workbook = XLSX.read(data, { type: 'array' });
				var first_sheet_name = workbook.SheetNames[0];
				var worksheet = workbook.Sheets[first_sheet_name];
				var jsonObj = XLSX.utils.sheet_to_json(worksheet, {
					raw: true,
					header: this.fullKeys,
					range: 1
				});

				let total = jsonObj.length;
				let valid = 0;
				const jsonArray: any = [];
				let framework = '';
				const uniqueControlNumbers = new Set<string>();
				let booleanValue = true;
				for (let i = 0; i < jsonObj.length; i++) {
					let item: any;
					item = jsonObj[i];
					let arr = ['controlNumber', 'controlFrameWork'];
					let mandatoryCondition = arr.every((key: any) => {
						return Object.keys(item).includes(key) && item[key];
					});
					let PriorityObj = this.priorityArray.find((obj: any) => {
						return obj.label == item.controlPriority;
					});
					item.controlPriority = PriorityObj.id;

					// let TypeObj = this.controlTypeArray.find((obj: any) => {
					// 	return obj.label == item.controlType;
					// });
					// item.controlType = TypeObj.id;

					if (mandatoryCondition) {
						if (!uniqueControlNumbers.has(item.controlNumber)) {
							booleanValue = false;
							framework = 'InternalControls';
							jsonArray[item.controlFrameWork] = jsonArray[item.controlFrameWork] || [];
							jsonArray[item.controlFrameWork].push(item);
							uniqueControlNumbers.add(item.controlNumber);
						}
					}
				}
				if (booleanValue) {
					toast.error(`Control Number should be unique`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
				const controlSets: any = [];
				Object.keys(jsonArray).forEach((key) => {
					const temp = {
						controlFrameWork: framework,
						controlsList: jsonArray[key].map((item: any) => ({ ...item, isInternalControl: true }))
					};
					valid = valid + temp.controlsList.length;
					controlSets.push(temp);
				});
				// this.addControl = true;

				await this.addControlSource(controlSets, 'toast');
				if (controlSets.length > 0) {
					toast.info(`Imported ${total} rows, Valid rows: ${valid}, Ignored rows: ${total - valid}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				} else {
					toast.error(`Something went wrong`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
			};
			reader.readAsArrayBuffer(oFile);
		},
		openFileModal() {
			this.$refs.file.click();
			this.showBlock = false;
		}
	}
});
</script>

