<template>
    <div class="tableCornerRadiusForAudit mb-16 bg-white">
        <div class="overflow-x-auto">
            <table>
                <thead>
                    <th scope="col" v-for="columnName in columnNames" :key="columnName" :class="getColumnHeadWidth(columnName)" class="bg-white capitalize" @click="sort(columnName)">
                        <div class="flex justify-center items-center" v-if="columnName == 'deleteIcon'">
                            </div> 
                        <div class="flex" v-else>
                            <div>{{ columnName }}</div>
                            <div class="sort cursor-pointer" :class="className == columnName ? classSort : 'opacity-50'"></div>
                        </div>
                    </th>
                </thead>
                <tbody v-if="sortedData.length == 0">
                    <tr class="cursor-pointer empty rowHeight">
                        <td class="relative h-10" :colspan="columnNames.size">
                            <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                        </td>
                    </tr>
                </tbody>
                <tbody class="nonEmptyTable" v-else>
                    <tr v-for="(row, index) in sortedData" :key="row" class="cursor-pointer rowHeight">
                        <td v-for="columnName in columnNames" :key="columnName" @mouseout="doFalse()" class="cursor-pointer relative">
                            <div class="my-1" v-if="columnName == 'teamOwner'">
                                <multiselect
                                    v-model="row.teamOwner"
                                    :options="TeamOwnerOptions"
                                    :showNoOptions="false"
                                    @search-change="asyncFind1"
                                    @select="checkAfterRequired('check')"
                                    :multiple="false"
                                    :close-on-select="true"
                                    :clear-on-select="true"
                                    :hide-selected="true"
                                    open-direction="bottom"
                                    label="label"
                                    track-by="value"  
                                    :searchable="true"
                                    :hideArrow="false"
                                    placeholder="Team Owner"
                                    class="custom-multiselect userSelect"
                                    :class="{
                                        'is-invalid borderRed': validationErrors[index]?.teamOwner || validationErrors[index]?.sameAuditorAuditee
                                    }"
                                >
                                </multiselect>
                                <div class="text-red-500">		
                                    <div class="error-text" v-if="validationErrors[index]?.teamOwner">Required</div>
                                    <div class="error-text" v-if="validationErrors[index]?.sameAuditorAuditee">Team Owner and Reviewer cannot be the same</div>
                                </div>
                            </div>
                            <div class="mt-1" v-else-if="columnName == 'reviewer'">
                                <multiselect
                                    v-model="row.reviewer"
                                    :options="reviewerOptions"
                                    :showNoOptions="false"
                                    @search-change="asyncFind"
                                    @select="checkAfterRequired('check')"
                                    :multiple="false"
                                    :close-on-select="true"
                                    :clear-on-select="true"
                                    open-direction="bottom"
                                    label="label"
                                    track-by="value"  
                                    :hide-selected="true"  
                                    :searchable="true"
                                    :hideArrow="false"
                                    placeholder="Reviewer"
                                    class="custom-multiselect userSelect"
                                    :class="{
                                        'is-invalid borderRed': validationErrors[index]?.reviewer || validationErrors[index]?.sameAuditorAuditee
                                    }"
                                >
                                </multiselect>
                                <div class="text-red-500">		
                                    <div class="error-text" v-if="validationErrors[index]?.reviewer">Required</div>
                                    <div class="error-text" v-if="validationErrors[index]?.sameAuditorAuditee">Team Owner and Reviewer cannot be the same</div>
                                </div>
                            </div>
                            <div v-else-if="columnName=='entity'">
                                <div class="table-ellipsis">
                                    <div @mousemove="toolTip(columnName, $event, index, row, 'text')">
                                        {{getLocationName(row[columnName])  }}
                                    </div>
                                </div>
                                <div v-if="pop == true && sortedData[tipIndex][columnName] == tipText && tipColumn == columnName && tipIndex == index">
                                    <tool-tip :columnName="columnName" :tipText="tipText" :styling="styling"></tool-tip>
                                </div>
                            </div>
                            <div v-else-if="columnName == 'deleteIcon'">
                                    <div class="flex pl-4">
                                        <img src="@/assets/deleteIcon.svg" class="h-5 cursor-pointer w-5 mx-2.5" @click="emitDeleteRowEvent(row)"/>
                                    </div>
                                </div>
                            <div v-else-if="columnName=='team'">
                                <div class="table-ellipsis">
                                    <div @mousemove="toolTip(columnName, $event, index, row, 'text')">
                                        {{getTeamName(row[columnName])  }}
                                    </div>
                                </div>
                                <div v-if="pop == true && sortedData[tipIndex][columnName] == tipText && tipColumn == columnName && tipIndex == index">
                                    <tool-tip :columnName="columnName" :tipText="tipText" :styling="styling"></tool-tip>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :entityId="entityId" :sortedData="sortedData"></pagination>
        </div>
    </div>
    
</template>
<style scoped>
tr:last-child {
    border-bottom: 1px solid #e9e9e9 ;
}
.w-40 {
    width: 155px;
}
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.borderRed {
  border: 1px solid red !important;
}
</style>

<script lang="ts">
import { emitter, toast } from '@/main';
import Multiselect from 'vue-multiselect';
import _ from 'lodash';
import { defineComponent } from 'vue';
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/pagination.vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { mapGetters } from 'vuex';

export default defineComponent({
    props: ['tableRowData', 'tabelActionBtn', 'tableRouteArr', 'departmentArr', 'headersData', 'locations','userArray'],
    data(): any {
        return {
            v$:useVuelidate(),
            tableHead: [],
            tableRow: [],
            searchTableRow: [],
            entityId: '',
            visibility: false,
            className: '',
            classSort: '',
            currentSort: 'name',
            currentSortDir: 'asc',
            showDropDown: false,
            showBlock: false,
            validationErrors: [],

            currentPage: '',
            pageSize: '',
            dynamicPagination: '',

            pop: false,
            styling: { left: '', bottom: '' },
            tipText: '',
            tipColumn: '',
            tipIndex: '',
            filters: {},
            users: [],
            optionsUserArray: [],
            TeamOwnerOptions:[],
            reviewerOptions:[],
            selectedRowIndex: '',
            action:'',
            userInfoFilteredDetails : {},
        };
    },
    validations(){
		return{
            sortedData: {
                $each: helpers.forEach({
                    teamOwner:{required},
                    reviewer:{required}
                })
            }
        }
    },
    components: {
        toolTip,
        Multiselect,
        pagination
    },
    async mounted() {
        this.initialize();
        await this.getAllUsers();
        await this.checkAfterRequired('check')
        emitter.on('visible', (isBoolean: any) => {
            this.visibility = isBoolean;
            this.visibility == false ? (this.filters = {}) : '';
        });
        
        emitter.off('checkAuditValidation')
        emitter.on('checkAuditValidation', (action:any) => {
            this.action = action;
            this.v$.$touch();
            console.log('cam intooooo checkAuditValidationcheckAuditValidation')
            if(!this.v$.sortedData.$invalid){
                this.checkAfterRequired('save')
            }else{
                toast.error(`Validation Error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        });
        // let userInfoFilteredDetails = this.userInfo;
            // this.userInfoFilteredDetails = {
            //   label: this.userInfo.name,
            //   value: this.userInfo._id,
            // };
            // console.log('userInfoFilteredDetails',this.userInfoFilteredDetails)
            // this.optionUserArray.push(obj);
    },
    computed: {
            ...mapGetters({ userInfo: "userInfo" }),
        sortedData: function (): any {
            return [...this.tableRow]
                .filter((row: any) => {
                    for (const key in this.filters) {
                        const inputValue = this.filters[key];
                        if (inputValue !== '' && row[key]) {
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
        columnNames: function (): any {
            const names = new Set();
            for (const key of this.headersData) {
                if (key != 'entityId' && key != '_id') names.add(key);
            }
            return names;
        }
    },
    watch: {
        visibility(newValue) {
            if (newValue == false) {
                this.initialize();
            }
        },
        tableRowData: {
            handler(newVal, oldVal) {
                this.tableRow = this.tableRowData;
                this.initialize();
                console.log('this.tableRowData[0]', this.tableRowData[0]);
            },
            deep: true
        }
    },
    methods: {
        asyncFind1(query:any){
            if(query == ''){
                this.TeamOwnerOptions = []
            }else if(query.length > 1){
                this.TeamOwnerOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
            }
        },
        asyncFind(query:any){
            if(query == ''){
                this.reviewerOptions = []
            }else if(query.length > 1){
                this.reviewerOptions = this.userArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
                console.log(" this.reviewerOptions", this.reviewerOptions)
                console.log("userArray",this.userArray)
            }
        },
        checkAfterRequired(action:any){
            this.validationErrors = this.sortedData.map((row:any) => {
                    return {
                        teamOwner: !row.teamOwner,
                        reviewer: !row.reviewer,
                        sameAuditorAuditee: row.reviewer.value === row.teamOwner.value
                    };
                });

                if(action == 'save'){
                    const hasErrors = this.validationErrors.some((errors:any) => 
                        errors.teamOwner || errors.reviewer || errors.sameAuditorAuditee
                    );

                    if (hasErrors) {
                        // Prevent saving and show validation messages
                        console.log('Prevent saving and show validation messages')
                    }else{
                        emitter.emit('auditValidationDone',this.action)
                        console.log('auditValidationDoneauditValidationDoneauditValidationDone')
                    }
                }
        },
        emitDeleteRowEvent(row:any) {
            // Emit the deleteRowFromTable event with the necessary data
            emitter.emit('deleteRowFromTable', {
                team: row.team,
                entity: row.entity
            });
        },
        initialize() {
            this.tableRow = this.tableRowData;
            this.searchTableRow = this.tableRowData;

            this.tableRowData[0] == undefined ? '' : (this.entityId = this.tableRowData[0].entityId);
            console.log('this.entityId', this.entityId);
        },
        paginationUpdations(currentPage: any, pageSize: any) {
            console.log('Pagination processing... ', currentPage, pageSize);
            this.currentPage = currentPage;
            this.pageSize = pageSize;
        },
        sort(tableHead: any) {
            this.className = tableHead;
            if (tableHead === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = tableHead;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
            this.tipText = row[column];
            this.tipColumn = column;
            this.tipIndex = index;

            switch (status) {
                case 'text':
                    this.handleTextTooltip(e);
                    break;
                case 'circleButton':
                    if (e.offsetX < e.target.offsetWidth && e.offsetY < e.target.offsetHeight) {
                        this.pop = true;
                        this.styling.left = e.offsetX + e.target.offsetLeft + 13 + 'px';
                        this.styling.bottom = -18 + 'px';
                    }
            }
        },
        handleTextTooltip(e: any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                this.pop = true;
                this.styling.left = e.offsetX + 38 + 'px';
                this.styling.bottom = -75 + 'px';
            } else {
                this.pop = false;
            }
        },
        isTextOverflowed(element: any) {
            return element.scrollHeight > element.clientHeight;
        },
        getTeamName(deptId: any) {
			console.log('deptId', deptId);
			console.log('this.departmentArr', this.departmentArr);
			let nameOfTeam = this.departmentArr.find((obj: any) => {
				return obj._id == deptId;
			});
			if (nameOfTeam == undefined) {
				let empty = '';
				return empty;
			} else {
				return nameOfTeam.teamName;
			}
		},
        getLocationName(locationId: any) {
			let user = this.locations.find((obj: any) => {
				return obj.value == locationId;
			});
			if (user == undefined) {
				let empty = '';
				return empty;
			} else {
				return user.label;
			}
		},
        getColumnHeadWidth(columnName:any){
			if(columnName == "deleteIcon"){
				return 'w-24';
			}
            if(columnName == "team" || columnName=='entity'){
                return 'w-32';
            }if(columnName == "teamOwner" || columnName=='reviewer'){
                return 'w-44';
            }


		},
        doFalse() {
            this.pop = false;
        },
        async getAllUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.users = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
            }
        },
    }
});
</script>
