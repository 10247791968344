<template>
	<div class="px-5 pt-3 pb-2 mb-5 ResponsiveAudit">
		<div class="items-center justify-between flex">
			<div class="title flex items-center">
				<h1>My Finding Tasks</h1>
			</div>
			<div class="flex items-center">
				
				<button class="ml-3 flex" @click="filterOpen" :class="tableRow.length == 0 ? 'filterButtondisabled pointer-events-none' : 'filterButton'">
					Filters
					<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
				</button>
			</div>
		</div>
		<div class="mt-3">
			<p class="headerText">
				An IT audit or information technology audit is an invertigation and evaluation of IT system,infrastructures,policies, and operations. Through IT audits, a company can determine if the existing IT controls protect corporate assets, ensure data integrity and align with the organization's business and financial controls.
			</p>
		</div>
        <!-- {{ tableRow }} -->
		<div>
			<tableData :tableRowData="tableRow" :headersData="headersTable" :loadData="dataLoading"></tableData>
			<div v-if="toolTipActive">
				<tool-tip :tipText="toolTipText"></tool-tip>
			</div>
		</div>
	</div>
</template>
<style scoped>
.btnPrimaryPadding{
	padding-left : 16px;
	padding-right : 16px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { required } from '@vuelidate/validators';
import tableData from '@/components/tableData.vue';
import useVuelidate from '@vuelidate/core';
import { mapGetters } from 'vuex';
import { emitter, toast } from '@/main';
import toolTip from '@/components/toolTip.vue'

export default defineComponent({
	data(): any {
		return { 
			v$: useVuelidate(),
			savedAuditSets: [],
			optionsUserArray: [],
			showFilters: false,
            actionBtn: true,
            allEntities: [],
			dummyList: [],
			tableRow: [],
			orgUsersList: [],
			teamsList: [],
			optionUserArray:[],
            allTasks:[],
			headersTable:['taskId','action','department','Entity','reviewer','statusCode'],
			toolTipActive:false,
			toolTipText:'',
			dataLoading:true,
		};
	},
	components: {
		tableData,
		toolTip
	},
	
	
	methods: {
        async getallEntities() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                this.allEntities = res.data;
            });
        },
		async getAllTeams() {
			this.teamObject = [];
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
				this.teamsList = res.data;
			});
		},
		async getallUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.orgUsersList = res.data;
			});
		},
		async getAllFindingTasks(){
            await this.$http
				.get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/tasks/getall`)
				.then((result: any) => {
					this.allTasks = result.data;
					this.allTasks.forEach((task:any) => {
						console.log('task',task);
						
						let taskReviewer = this.orgUsersList.find((user: any) => {
							return user._id == task.reviewer;
						})
						task.reviewer = taskReviewer != undefined ? taskReviewer.name : 'N/A'
						let taskDepartment = this.teamsList.find((team: any) => {
							return team._id == task.department;
						})
						task.department = taskDepartment != undefined ? taskDepartment.teamName : 'N/A'
						let entity = taskDepartment != undefined ? taskDepartment.entityName : 'N/A'
						
						
                        if (task.actionType === 2) {
						// 	if(task.entitytypecodeid == 20301){
                        //             let primaryEntity: any
                        //             // let entityObj = this.allEntities.find((entity: any)=>{return entity.entityType == 1})
                        //             primaryEntity = 'Global'
                        //             this.tableRow.push({ ...task, 'location': primaryEntity });
                        //         }
                        //    else if(task.entitytypecodeid == 20303){
						// 		// console.log('NOT GLOBAL',task.entity != 'Global',task.entity)
                        //         // task.entity.forEach((locationObj: any) => {
                        //         //     // Create a new object with the original task properties and assign locationObj to the entity key
                        //         //     console.log("locationObj",locationObj)
                        //             let entityObj = this.allEntities.find((entity: any)=>{
						// 				return entity._id == task.entity
						// 			})
                        //             this.tableRow.push({ ...task, 'location': entityObj ? entityObj.entityName : 'N/A' });
                        //         // });
                        //     } else{
								this.tableRow.push({ ...task, 'Entity': entity});
							// }
                        }
                   });
            });
            
        },
        goToFindingsInfo(obj:any){
            // this.$router.push({ name: 'actionTask', params: { findingId: "6695023c6c515f7a842766df" , taskId: "669505aca52488584835a1c9"} });
            this.$router.push({ name: 'actionTask', params: { findingId: obj.entityData.linkedFindingId , taskId: obj.entityData._id} });
        },
		filterOpen() {
			this.showFilters = !this.showFilters;
			if (this.showFilters == true) {
                this.dummyList = this.tableRow;
            } else {
                this.tableRow = this.dummyList;
            }
			emitter.emit('visible', this.showFilters);
		},
        
	},
	async created() {
		this.dataLoading = true;
		await this.getallUsers();
		await this.getAllTeams();
        await this.getallEntities();
        await this.getAllFindingTasks();
		this.dataLoading = false;
        emitter.off('allFindingsTasks')
		emitter.on('allFindingsTasks',async (actionObj:any)=>{
            console.log('áskhdsakudbs',actionObj)
            this.goToFindingsInfo(actionObj)
		})
		emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {                        
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        }); 
    }  
});
</script>
 