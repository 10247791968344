import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7994b52a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "body-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_MenuBar = _resolveComponent("MenuBar")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavBar),
    _createElementVNode("div", {
      class: "flex",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeApps()))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass({ 'w-40': _ctx.isTogglingData, 'w-10': !_ctx.isTogglingData })
      }, [
        _createVNode(_component_MenuBar)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["content flex-1 overflow-y-scroll h-screen w-full", { toggledstate: _ctx.isTogglingData }]),
        onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleScroll && _ctx.handleScroll(...args)))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.getPadding())
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2),
        _createVNode(_component_Footer)
      ], 34)
    ])
  ]))
}