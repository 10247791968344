<template>
  <div class="pt-3 pl-5 pr-5">
    <div class="mb-2">
        <breadCrumb :routesArray="routesArray"></breadCrumb>
    </div>
    <div v-if="isLoading">
      <div
        class="row w-full mb-3 justify-between flex"
        style="align-items: center"
      >
        <h1 class="title">Controls</h1>
        <div class="cardbody">
          <div class="flex flex-row-reverse items-center">
            <button
              class="flex"
              @click="filterOpen" :class="tableRow.length == 0 ? 'filterButtondisabled pointer-events-none' : 'filterButton'"
            >
              Filters
              <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
            </button>
          </div>
        </div>
      </div>
      <div>
        <tableData
          :tableRowData="tableRow"
          :headersData="headers"
          :departmentArr="deptOptions"
          :loadData="dataLoading"
        ></tableData>
      </div>
      <popup v-if="showPopup" :popUp="showPopup">
        <!-- {{allKpis}} -->
        <template v-slot:header>
          <div class="flex items-center justify-between py-4 px-2 rounded">
            <h1 class="pl-2" v-if="allKpis.controlNumber">
              {{ allKpis.controlNumber }}
            </h1>
            <h1 class="pl-2" v-else>{{ allKpis.description }}</h1>
            <button class="float-right text-3xl" @click="showPopup = false">
              <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
            </button>
          </div>
        </template>
        <fieldset>
          <div>
            <div>
              <div class="statuscontainer popupbodycontainer">
                <div class="formcontainer flex flex-col">
                  <div class="row flex flex-row w-full upperspacing">

                
                   <div class="w-full">
                        <label class="controllabel">Department</label>
                        <input
                          type="text"
                          class="inputboxstyling"
                          v-model="allKpis.department"
                          maxlength="3"
                          ref="editWeight"
                          placeholder="Description"
                          disabled
                        />
                      </div>

                        </div>
                  <div class="row flex flex-row w-full upperspacing">
                    <div class="w-full">
                      <label class="controllabel">KPI ID</label>
                      <input
                        type="text"
                        class="inputboxstyling"
                        v-model="allKpis.kpi_id"
                        placeholder="KPI ID"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row flex flex-row w-full upperspacing">
                    <div class="w-full">
                      <label class="controllabel">KPI Name</label>
                      <textarea
                        type="text"
                        class="inputboxstyling h-20 max-h-48"
                        :disabled="true"
                        v-model="allKpis.description"
                        placeholder="Description"
                      ></textarea>
                    </div>
                  </div>
                  <!-- <div class="row flex flex-row w-full upperspacing">
										<div class="w-full">
											<label class="controllabel">Departments</label>
											<div v-if="controlDepartmentArr.length > 0">
												<Multiselect
													v-model="controlDepartmentArr"
													mode="tags"
													:close-on-select="false"
													:classes="{
														container: 'relative h-fit mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-lightgrey rounded bg-white text-base leading-snug outline-none'
													}"
													:options="deptOptions"
													:caret="false"
													:showOptions="false"
													:canClear="false"
													:disabled="true"
													placeholder="Departments"
												>
													<template v-slot:tag="{ option, handleTagRemove, disabled }">
														<div
															class="multiselect-tag"
															:class="{
																'is-disabled': disabled
															}"
														>
															{{ option.label }}
															<span v-if="!disabled" class="multiselect-tag-remove" @click="handleTagRemove(option, $event)">
																<span class="multiselect-tag-remove-icon"></span>
															</span>
														</div>
													</template>
												</Multiselect>
											</div> -->
                  <!-- <div v-else>
												<input class="inputboxstyling" :disabled="true" v-model="naDepartments" placeholder="Departments" />
											</div> -->
                  <!-- </div> -->
                  <!-- </div> -->
                  <div class="formcontainer flex flex-col upperspacing">
                    <div class="row flex flex-row w-full">
                      <div class="w-6/12">
                        <label class="controllabel">Weightage (%)</label>
                        <input
                          type="number"
                          class="inputboxstyling"
                          v-model="allKpis.weightage"
                          maxlength="3"
                          ref="editWeight"
                          placeholder="Weightage (%)"
                          disabled
                        />
                      </div>
                      <!-- <div class="w-3/6 leftspacing">
												<label class="controllabel">Target Time Period</label>
												<input class="inputboxstyling" :disabled="true" v-model="allKpis.targetTimePeriod" placeholder="Target (%)" />
											</div> -->
                       
                    </div>
                  </div>

                  <!-- <h1 class="upperspacing" v-if="hideFormula && allKpis.metrics">Metrics</h1>
									
									<div class="row flex w-full gap-16" v-if="allKpis.metrics">
										<div class="w-4/6">
											
												<div v-for="(metric, metricIndex) in allKpis.metrics" :key="metricIndex">
													<label class="controllabel">{{ metric.key }}</label>
													<input type="text" class="inputboxstyling" :disabled="true" v-model="allKpis.metrics[metricIndex].label" />
												</div>
											
										</div>

										<div class="w-2/6 flex justify-center items-center" v-if="hideFormula">
											<div class="">
												<label class="controllabel font-normal text-sm mb-1">Formula</label>
												<input type="text" class="inputboxstyling" :disabled="true" v-model="allKpis.isformula" placeholder="Formula" />
											</div>
										</div>
									</div> -->
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </popup>
    </div>
    <!-- <loader v-if="showLoader" /> -->
  </div>
</template>
<style scoped>
.multiselect-tag {
  font-size: 12px;
  font-weight: 600;
}
.multiselect-tag.is-disabled {
  background-color: #059669 !important;
}
.multiselect.is-disabled {
  background-color: white !important;
}
</style>
<script lang="ts">
import * as XLSX from 'xlsx';
// import loader from "../../components/loader.vue";
import _ from "lodash";
import { defineComponent } from "vue";
import { emitter, toast } from "@/main";
import popup from "@/components/popup.vue";
import tableData from "@/components/tableData.vue";
import breadCrumb from '@/components/breadCrumb.vue';
// import Multiselect from '@vueform/multiselect/src/Multiselect';
export default defineComponent({
  data(): any {
    return {
      headers: ["_id","departments", 'Entity', "CCID", "Title"],

      isLoading: false,
      showLoader: false,

      showFilters: false,
      hideFormula: false,
      showPopup: false,
      columnObject: false,

      dummyList: [],
      allKpisList: [],
      routesArray: [],
      tableRow: [],
      departmentsList: [],
      controlDepartmentArr: [],
      internalControlsDetailsArray: [],
      allEntities: [],
      entitiesArray: [],
      naDepartments: "",
      dataLoading:true,
      kpiList: [],
      deptOptions: [],
      allKpis: {
        kpi_id: "",
        weightage: "",
        description: [],
      },
      controlRecurringArray: [
        { label: "Daily", id: 1 },
        { label: "Weekly", id: 2 },
        { label: "Monthly", id: 3 },
        { label: "Quarterly", id: 4 },
        { label: "Half-Yearly", id: 5 },
        { label: "Yearly", id: 6 },
      ],
    };
  },
  components: {
    tableData,
    // loader,
    popup,
    breadCrumb
    // Multiselect
  },
  async mounted() {
    await this.fetchDepartmentsList();
    await this.getallEntities();
    await this.fetchAllKpisInfo();
    await this.startSelectedCompliance(this.$route.params.complianceId);
    emitter.on("showKpisViewData", (isData: any) => {
      let comingId = isData.entityData._id;
      let showObj = this.kpiList.find((obj: any) => {
        return obj._id === comingId;
      });
      this.showEditPopUp(showObj, isData.indexx);
    });
    await this.makeTable();
    this.dataLoading = false;
  },
  created(){
    this.dataLoading = true;
    this.routesArray = [
      { name: "Compliance", routeName: "compliance" },
      { name: "Controls", routeName: "complianceControls" }
    ];  
  },
  methods: {
    showEditPopUp(obj: any, index: any) {
      this.showPopup = true;
      this.allKpis = { ...obj };
      this.hideFormula = true;
      // if (this.tableRow[index].departments == 'N/A') {
      // 	this.naDepartments = this.tableRow[index].departments;
      // } else {
      // 	this.controlDepartmentArr = this.tableRow[index].departments;
      // }
      // if (Number.isInteger(obj.targetTimePeriod)) {
      // 	this.allKpis.targetTimePeriod = this.controlRecurringArray.find((itemOne: any) => {
      // 		return itemOne.id == obj.targetTimePeriod;
      // 	}).label;
      // }
      // if (Number.isInteger(obj.reportingFrequency)) {
      // 	this.allKpis.reportingFrequency = this.controlRecurringArray.find((itemOne: any) => {
      // 		return itemOne.id == obj.reportingFrequency;
      // 	}).label;
      // }
    },
    filterOpen() {
      if (this.columnObject == false) {
        this.showFilters = !this.showFilters;
        emitter.emit("visible", this.showFilters);
      }
    },
    async fetchAllKpisInfo() {
      try {
        await this.$http
          .get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/kpis/getall`)
          .then((result: { data: any }) => {
            this.isLoading = true;
            this.showLoader = true;
            this.allKpisList = result.data;
            _.each(
              this.allKpisList,
              (kpi: {
                kpis: any;
                controlNumber: any;
                controlTitle: any;
                departments: any;
              }) => {
                if (kpi.kpis) {
                  _.each(
                    kpi.kpis,
                    (kpi: {
                      controlNumber: any;
                      targetTimePeriod: any;
                      reportingFrequency: any;
                      controlTitle: any;
                      departments: any;
                    }) => {
                      // kpi.departments = kpi.departments;
                      this.kpiList.push(kpi);
                    }
                  );
                  this.dummyList = this.kpiList;
                }
              }
            );
          });
      } catch (e) {
        toast.error("error", {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }
      this.showLoader = false;
    },
    async fetchDepartmentsList() {
      (this.showLoader = true),
        //  (this.isLoading = false);
        await this.$http
          .get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`)
          .then((res: { data: any }) => {
            this.isLoading = true;
            this.showLoader = false;
            this.departmentsList = res.data;
          })
          .catch((error: any) => {
            this.isLoading = false;
            (this.showLoader = false),
              toast.error(`Save failed with ${error}`, {
                timeout: 1000,
                closeOnClick: true,
                closeButton: "button",
                icon: true,
              });
          });
    },
    async getallEntities() {
      let result: any;
      await this.$http
        .get(
          `${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`
        )
        .then((res: any) => {
          result = res.data;
          this.allEntities = res.data;
        });
      for (var i = 0; i < result.length; i++) {
        this.entitiesArray.push({
          label: result[i].entityName,
          value: result[i]._id,
        });
      }
    },
    async startSelectedCompliance(cameId:any){
			await this.$http.get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/compliance/${cameId}/startCompliance`).then((result: any) => {
        this.internalControlsDetailsArray = [...result.data];
			})
		},
    // makeTable() {
    //   let eachControlData: any
    //   this.internalControlsDetailsArray.forEach((controlData: any) => {
    //     console.log("controlData", controlData);
    //     eachControlData = controlData.controls
    //     // this.tableRow.push(finalKPIObj);
    //   });
    //   console.log("kpi")
    //   if(eachControlData.length != 0){
    //     eachControlData.forEach((kpi:any)=>{
    //       if(Object.keys(kpi).includes('kpis') && kpi.kpis.length !== 0){
    //         this.departmentsList.forEach((eachdepartment: any) => {
    //           if (kpi.departments == eachdepartment._id) {
    //             kpi.departments = eachdepartment.teamName;
    //           }
    //         });
    //         this.deptOptions = this.departmentsList.map((item: any) => {
    //           return { value: item._id, label: item.teamName };
    //         });
    //         let finalKPIObj
    //         kpi.kpis.map((eachKpi: any)=>{
    //           finalKPIObj = {
    //             _id: kpi._id,
    //             departments: kpi.departments ? kpi.departments : "N/A",
    //             number: kpi.controlNumber ? kpi.controlNumber : "N/A",
    //             "KPI Name": eachKpi.description,
    //             "weightage (%)": parseInt(eachKpi.weightage)
    //           };
    //         })
    //       this.tableRow.push(finalKPIObj);
    //       }
    //     })
    //   }
    // },
    makeTable() {
      this.internalControlsDetailsArray.forEach((controlData: any) => {
        const eachControlData = controlData.controls;
        if (eachControlData && eachControlData.length) {
          eachControlData.forEach((control: any) => {
            let deptName = this.departmentsList.find((dept: any) => dept._id === control.departments);
            control.departments = deptName?.teamName || control.departments
            let entityName:any
            if(deptName.entityType == 20301){
              entityName = 'Global'
            }
            else if (deptName.entityType == 20303){
              entityName = deptName.entityName != undefined ? deptName.entityName : 'N/A'
            }
            this.tableRow.push({
              _id: control._id,
              'CCID': control.clientUniqueId,
              departments: control.departments || "N/A",
              Entity: entityName != undefined ? entityName : 'N/A',
              'Title': control.controlTitle || "N/A",
            });
            this.dataLoading = false;
            // if (kpi.kpis && kpi.kpis.length) {
            //   kpi.departments = this.departmentsList.find((dept: any) => dept._id === kpi.departments)?.teamName || kpi.departments;
            //   this.deptOptions = this.departmentsList.map((item: any) => ({
            //     value: item._id,
            //     label: item.teamName
            //   }));
            //   kpi.kpis.forEach((eachKpi: any) => {
            //     this.tableRow.push({
            //       _id: kpi._id,
            //       departments: kpi.departments || "N/A",
            //       'Control Number': kpi.controlNumber || "N/A",
            //       "KPI Name": eachKpi.description,
            //       "weightage (%)": parseInt(eachKpi.weightage)
            //     });
            //   });
            // }
          });
        }
      });
      for (const row of this.tableRow) {
        for (const value of Object.values(row)) {
          if (value == "") {
            this.columnObject = true;
          } else {
            this.columnObject = false;
          }
        }
      }
    }
  },
});
</script>

