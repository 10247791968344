<template>
   <!-- <loader v-if="showLoader" /> -->
  <div class="mb-5 mx-5 mt-3 ResponsiveControlePage">
    <div class="mb-2">
      <breadCrumbs
        :routesArray="routesArray"
      ></breadCrumbs>
      <!-- {{ enableApproveAndReject }} -->
    </div>
    <div class="row w-full h-full flex mt-2 justify-between">
      <div class="w-2/5 flex items-center">
        <h1 class="title">{{ presentFindingTaskObj.taskId }}</h1>
      </div>
      <!-- <div
        class="flex flex-row"
        v-if="
          !checkreviewer(presentFindingTaskObj.reviewer) &&
          (presentFindingTaskObj.statusCode == 10419 ||
            presentFindingTaskObj.statusCode == 10415 ||
            presentFindingTaskObj.statusCode == 10403)"
      >
        <button
          class="btnprimary items-center btnPrimaryPadding mr-2"
          @click="approveOrReject(10403)"
        >
          Approve
        </button>
        <button
          class="btnprimary items-center btnPrimaryPadding"
          @click="approveOrReject(10410)"
        >
          Reject
        </button>
      </div>
	  <div
        class="flex flex-row"
        v-if="
          checkreviewer(presentFindingTaskObj.reviewer) &&
          (presentFindingTaskObj.statusCode == 10419 ||
            presentFindingTaskObj.statusCode == 10415 ||
            presentFindingTaskObj.statusCode == 10403)"
      >
        <button
          class="btnprimary items-center btnPrimaryPadding mr-2"
          @click="acceptOrReject(10403, 9)"
        >
          Accept
        </button>
        <button
          class="btnprimary items-center btnPrimaryPadding"
          @click="acceptOrReject(10401, 9)"
        >
          Reject
        </button>
      </div> -->
      <!-- for total task approval -->
			<div class="flex flex-row" v-if="presentFindingTaskObj.reviewer == userInfo._id && ( presentFindingTaskObj.statusCode == 10415)">
				<button class="btnprimary items-center btnPrimaryPadding mr-2" @click="approveOrRejectForActionTasktoComplete(10403,9)">Approve</button>
				<button class="btnprimary items-center btnPrimaryPadding" @click="onClickToRejectByJustificationAtInReview()">Reject</button>
			</div>

			<!-- for normal approval -->
			<div class="flex flex-row" v-if="presentFindingTaskObj.approvals!= undefined && presentFindingTaskObj.approvals[presentFindingTaskObj.approvals.length - 1].statusCode== 10401 && presentFindingTaskObj.approvals[presentFindingTaskObj.approvals.length - 1].approver == userInfo._id">
          <button class="btnprimary items-center btnPrimaryPadding mr-2" @click="ApprovalforInprogress(10403)">Approve</button>
          <button class="btnprimary items-center btnPrimaryPadding" @click="clickToRejectByJustification()">Reject</button>
      </div>
    </div>
    <div class="w-full minheight">
      <div class="w-full">
        <div class="text-sm">
          <div
            class="mainContainer tabStructure flex flex-row w-full bg-white border rounded-sm mt-5 px-5 pt-5 pb-3 border-lightgrey"
          >
            <div class="w-75 heightFixing pr-5">
              <div class="tabStructureDiv">
                <div
                  class="flex flex-row borderBottom justify-between cursor-pointer tab-container"
                >
                  <div
                    class="pb-1"
                    :class="
                      selectedTabIndex == tab.id
                        ? 'adjust header font-semibold text-sm primary'
                        : 'text-sm text-disabledgrey'
                    "
                    v-for="(tab, tabIndex) in tabHead"
                    :key="tabIndex"
                    @click="toggleTabs(tab)"
                  >
                    <div>
                      <div class="cursor-pointer">{{ tab.displayName }}</div>
                      <div
                        :class="{ progress: selectedTabIndex == tab.id }"
                        class="h-1 p-0.75"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="progressbarB rounded h-1"></div>
              </div>
              <div class="h-full">
                <div
                  class="overflow-y-scroll descriptionToggle"
                  v-if="selectedTabObj.id == 1"
                >
                  <div>
                    <div>
                    <div
                      class="border-b border-lightgrey flex justify-between mb-3 paddingBottomForFindings"
                    >
                      <div class="flex items-center justify-center">
                        <div>
                          <p class="header mt-0.5">Action</p>
                        </div>
                      </div>
                    </div>
                  </div>
                    <p class="mt-1.5 text-xs">{{ (presentFindingTaskObj.action != undefined ? presentFindingTaskObj.action : 'N/A') }}</p>
                  </div>
                </div>
                <div
                  class="overflow-y-scroll descriptionToggle"
                  v-if="selectedTabObj.id == 2"
                >
                  <div>
                    <div
                    >
                    <div
                      class="border-b border-lightgrey flex justify-between mb-3 paddingBottomForFindings"
                    >
                      <div class="flex items-center justify-center">
                        <div>
                          <p class="header mt-0.5">Documents which are related to the controls</p>
                        </div>
                      </div>
                    </div>
                    </div>
                    <!-- <p class="mt-1.5">{{ descriptionObject._id }}</p> -->
                  </div>
                </div>
                <div
                  class="overflow-y-scroll descriptionToggle"
                  v-if="selectedTabObj.id == 3"
                >
                  <div>
                    <!-- <div class="border-b border-lightgrey">
											<div class="flex justify-between mb-1.5">
												<div class="header flex justify-center items-center">Files </div>
												<div class="buttonposition flex justify-center items-center">
													 <input  type="file" id="upload" ref="element"/>
													<button class="btnprimary w-24 flex justify-center taskEditButtons">Attach</button> 
													<button @click="openFilePicker" for="upload" class="btnprimary w-24 flex justify-center taskEditButtons">Attach</button>
    												<input type="file" ref="fileInput" id="upload" @change.stop="handleFileChange" style="display: none" />
												</div>
											</div>
										</div> -->
                    
                    <!-- <div class="border-b border-lightgrey flex justify-end">
                      <div><p class="my-1.5 header">Files </p></div> 
                      <div class="mb-1.5">
                        <div
                          class="buttonposition flex justify-center items-center"
                        >
                          <button
                            @click="openFilePopup"
                            for="upload"
                            :class="
                              TaskDetailsDropDown.taskStatus == 10403 ||
                              TaskDetailsDropDown.taskStatus == 10417 ||
                              presentComplianceObject.statusCode == 10403 ||
                              presentComplianceObject.statusCode == 10418 || presentFindingTaskObj.reviewer == userInfo._id
                                ? 'btndisabled pointer-events-none paddingForAttachButton'
                                : 'btnprimary flex justify-center taskEditButtons'
                            "
                            class="w-24 flex justify-center"
                          >
                            Attach
                          </button>
                        </div>
                      </div>
                    </div> -->
                    <div
                      class="border-b border-lightgrey flex justify-between paddingAdjustment mb-5"
                    >
                      <div class="flex items-center justify-center">
                        <div>
                          <p class="header">Files</p>
                        </div>
                      </div>
                      <div>
                        <div
                          class="buttonposition flex justify-center items-center"
                        >
                          <div
                            class="buttonposition flex justify-center items-center"
                          >
                          <button
                            @click="openFilePopup"
                            for="upload"
                            :class="
                              TaskDetailsDropDown.taskStatus == 10403 ||
                              TaskDetailsDropDown.taskStatus == 10417 ||
                              presentComplianceObject.statusCode == 10403 || TaskDetailsDropDown.taskStatus == 10418 ||
                              presentComplianceObject.statusCode == 10418 || presentFindingTaskObj.reviewer == userInfo._id
                                ? 'btndisabled borderForBtndisabled pointer-events-none paddingForAttachButton'
                                : 'btnprimary flex justify-center taskEditButtons'
                            "
                            class="w-24 flex justify-center"
                          >
                            Attach
                          </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="mt-1.5">Files</p>
                    <div
                      v-if="
                        Object.keys(presentFindingTaskObj).includes('files')
                      "
                      class="flex mt-4 gap-4 flex-wrap"
                    >
                      <div
                        v-for="doc in presentFindingTaskObj.files"
                        :key="doc"
                      >
                        <div class="pdf w-full rounded-sm">
                          <a
                            :href="doc.url"
                            class="flex w-full flex-row items-center"
                          >
                            <img src="@/assets/pdfs.svg" alt="" />
                            <p
                              v-if="Object.keys(doc).includes('filename')"
                              class="text-xs ml-2"
                            >
                              {{ doc.filename }}
                            </p>
                            <p v-else class="text-xs ml-1">{{ doc.name }}</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="overflow-y-scroll descriptionToggle"
                  v-if="selectedTabObj.id == 4"
                >
                <div
                      class="border-b border-lightgrey flex justify-between paddingAdjustment mb-5"
                    >
                      <div class="flex items-center justify-center">
                        <div>
                          <p class="header">Comments</p>
                        </div>
                      </div>
                      <div>
                        <div
                          class="buttonposition flex justify-center items-center"
                        >
                          <div
                            class="buttonposition flex justify-center items-center"
                          >
                          <button
                              class="btnprimary w-24 flex taskEditButtons"
                              v-if="oldComments"
                              @click="sortComments()"
                            >
                              <img
                                src="@/assets/sortBy.svg"
                                class="h-3 mr-2.5"
                              />
                              Oldest
                            </button>
                            <button
                              class="btnprimary w-24 flex taskEditButtons"
                              v-if="!oldComments"
                              @click="sortComments()"
                            >
                              <img
                                src="@/assets/sortBy.svg"
                                class="h-3 mr-2.5 rotate-180"
                              />
                              Newest
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="comment-box w-full">
                          <div class="my-3">
                              <commentsEditor/>
                            </div>
                      </div>
                      <div v-if="Object.keys(presentFindingTaskObj).includes('comments')" class="comments">
                          <div v-for="(obj, index) in presentFindingTaskObj.comments" class="border border-lightgrey mt-2 rounded-sm flex flex-row p-2" :key="index">
                              <div class="w-1/5">
                                  <div class="header">{{ getPersonName(obj.createdBy) }}</div>
                                  <div class="fontSize10PX">{{ obj.createdAt }}</div>
                              </div>
                              <!-- <div class="w-4/5 text-xs">{{ obj.comment }}</div> -->
                              <!-- <div class="w-4/5 text-xs" v-html="obj.comment"></div> -->
                              <div class="w-4/5 text-xs">
                                <safeHtml :content="obj.comment"/>
                              </div>
                          </div>
                      </div>
                      <!-- <div v-if="Object.keys(presentFindingTaskObj).includes('comments')" class="comments">
                        <div
                          v-for="(obj, index) in presentFindingTaskObj.comments"
                          class="border border-lightgrey mt-2 rounded-sm flex flex-row p-2"
                          :key="index"
                        >
                          <div class="w-1/5 mr-5">
                            <div class="header">
                              {{ getPersonName(obj.createdBy) }}
                            </div>
                            <div class="fontSize10PX">{{ obj.createdAt }}</div>
                          </div>
                          <div class="w-4/5 text-xs">
                            <safeHtml :content="obj.comment"/>
                          </div>
                        </div>
                      </div> -->
                    </div>
                </div>
                <div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 5">
									<div>
                    <div
                      class="border-b border-lightgrey flex justify-between paddingAdjustment"
                    >
                      <div class="flex items-center justify-center">
                        <div>
                          <p class="header">Approvals</p>
                        </div>
                      </div>
                      <div>
                        <div
                          class="buttonposition flex justify-center items-center"
                        >
                          <div
                            class="buttonposition flex justify-center items-center"
                          >
                          <!-- {{userInfo._id}} ==
                          {{presentFindingTaskObj.teamOwner}} ---


                          {{approvalObj.statusCode}} ''''''''
                          {{TaskDetailsDropDown.taskStatus}}
                          {{presentFindingTaskObj.teamOwner == userInfo._id}}
                          {{presentFindingTaskObj.assignee != undefined && presentFindingTaskObj.assignee.value == userInfo._id}}
                          -----
                          {{TaskDetailsDropDown.taskStatus ==10419 || approvalObj.statusCode == 10401 || presentFindingTaskObj.statusCode == 10403 || (presentFindingTaskObj.teamOwner != userInfo._id || presentFindingTaskObj.assignee.value != userInfo._id)}} -->
                          <button @click="openApprovalpopup()" :class="(newAssignee.value != userInfo._id  && presentFindingTaskObj.teamOwner != userInfo._id) || TaskDetailsDropDown.taskStatus == 10418 || TaskDetailsDropDown.taskStatus ==10419 || approvalObj.statusCode == 10401 || presentFindingTaskObj.statusCode == 10403 || (presentFindingTaskObj.teamOwner != userInfo._id &&( presentFindingTaskObj.assignee != undefined && presentFindingTaskObj.assignee.value != userInfo._id)) ?'btndisabled pointer-events-none paddingForAttachButton' : 'btnprimary flex justify-center taskEditButtons'" class="w-32 flex justify-center ">Send Approval</button>
                          </div>
                        </div>
                      </div>
                    </div>
										<div>
											<div v-for="(approval, number) in presentFindingTaskObj.approvals" :key="number" class="bg-white flex flex-row p-4 mb-0.5 border-lightgrey border-b w-full">
												<div v-if="approval.createdBy != undefined" class="initials">{{extractInitials(getPersonName(approval.createdBy))}}</div>
												<div v-if="approval.createdBy != undefined" class="font-semibold mt-1 text-xs">{{ getPersonName(approval.createdBy) }}</div>
												<div v-if="approval.updatedBy != undefined" class="initials">{{extractInitials(getPersonName(approval.updatedBy))}}</div>
												<div v-if="approval.updatedBy != undefined" class="font-semibold mt-1 text-xs">{{ getPersonName(approval.updatedBy) }}</div>
												<div v-if="approval.statusCode == 10401" class="mx-2 mt-1 text-xs"> {{' Requested an approval '}} </div>
												<div v-if="approval.statusCode == 10410" class="mx-2 mt-1 text-xs"> {{' Rejected an approval '}} </div>
												<div v-else-if="approval.statusCode == 10403" class="mx-2 mt-1 text-xs"> {{' Approved an approval '}} </div>
												<div  v-if="approval.createdAt != undefined" class="font-semibold mt-1 text-xs">{{approval.createdAt}}</div>
												<div  v-if="approval.updatedAt != undefined" class="font-semibold mt-1 text-xs">{{approval.updatedAt}}</div>
											</div>
										</div>
									</div>
								</div>
                <div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 6">
									<div>
                    <div
                      class="border-b border-lightgrey flex justify-between paddingAdjustment"
                    >
                      <div class="flex items-center justify-center">
                        <div>
                          <p class="header">History</p>
                        </div>
                      </div>
                      <div>
                        <div
                          class="buttonposition flex justify-center items-center"
                        >
                          <div
                            class="buttonposition flex justify-center items-center"
                          >
                          <button class="btnprimary w-24 flex taskEditButtons" v-if="newHistory" @click="sortWorkLog()">
															<img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
															Oldest
														</button>
														<button class="btnprimary w-24 flex taskEditButtons" v-if="!newHistory" @click="sortWorkLog()">
															<img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
															Newest
														</button>
                          </div>
                        </div>
                      </div>
                    </div>
											<div>
												
												<div v-for="work in workLogObject.taskHistory" :key="work" class="bg-white p-4 mb-0.5 border-lightgrey border-b w-full">
													<div class="flex">
														<div v-if="checkKey(work,'createdBy')" class="initials">
															{{ extractInitials(getPersonName(work.createdBy)) }}
														</div>
														<div v-if="Object.keys(work).includes('updatedBy')" class="initials">
															{{ extractInitials(getPersonName(work.updatedBy)) }}
														</div>
														<div class="logContainer w-93">
															<div class="flex mt-1">
																<p v-if="Object.keys(work).includes('createdBy')" class="font-semibold mt-1 text-xs">
																	{{ getPersonName(work.createdBy) }}
																</p>
																<p v-else-if="Object.keys(work).includes('updatedBy')" class="font-semibold mt-1 text-xs">
																	{{ getPersonName(work.updatedBy) }}
																</p>

																<p v-if="work.actionEntity == 10301" class="mx-2 mt-1 text-xs">{{ 'Created the Task on' }}</p>
																<p v-else-if="work.statusCode != undefined && work.statusCode == 10418" class="mx-2 mt-1 text-xs">{{ 'Terminated the Task on' }}</p>
																<p v-else-if="work.actionEntity == 10302" class="mx-2 mt-1 text-xs">{{ 'Updated the Task on' }}</p>

																<p v-if="Object.keys(work).includes('createdAt')" class="font-semibold mt-1 text-xs">{{ formatTime(work.createdAt) }}</p>
																<p v-if="Object.keys(work).includes('updatedAt')" class="font-semibold mt-1 text-xs">{{ formatTime(work.updatedAt) }}</p>
															</div>
															
															<div class="mt-3" v-if="Object.keys(work).includes('additionalInfo')">
																
																	<div v-if="work.additionalInfo">
																		<div v-if="checkKey(work.additionalInfo,'comment')" class="flex">
																			<div class="font-semibold text-xs">Comment:</div>
                                      <div class="text-xs">
                                           <safeHtml :content="work.additionalInfo.comment"/>
                                      </div>
																		</div>
																	<div class="flex" v-if="Object.keys(work.additionalInfo).includes('evidence')">
																		<p class="font-semibold text-xs">{{'Uploaded the Evidence -'}}</p>
																		<p class="mx-2 text-xs">{{ work.additionalInfo.evidence }}</p>
																	</div>
																	<div class="flex" v-if="Object.keys(work.additionalInfo).includes('assignee')">
																		<p class="font-semibold text-xs">{{'Assigned the Task To -'}}</p>
																		<p class="mx-2 text-xs">{{ getPersonName(work.additionalInfo.assignee) }}</p>
																	</div>
																	<div class="flex" v-if="Object.keys(work.additionalInfo).includes('approver')">
																		<div v-if="work.additionalInfo.action == 10301">
																			<p class="font-semibold text-xs">{{'Sent Approval for -'}}</p>
																		</div>
																		<div v-if="work.additionalInfo.statusCode == 10403">
																			<p class="font-semibold text-xs">{{'Approved Approval -'}}</p>
																		</div>
																		<div class="flex" v-if="work.additionalInfo.statusCode == 10410">
																			<p class="font-semibold text-xs">{{'Rejected Approval -'}}</p>
																		</div>
																		<p class="mx-2 text-xs text-justify">{{ getPersonName(work.additionalInfo.approver) }}</p>
																   </div> 
																	<div class="flex items-center" v-if="Object.keys(work.additionalInfo).includes('previousStatus')">
                                      <div>
                                          <p class="font-semibold text-xs">{{'Updated the status :'}}</p>
                                      </div>
																	<!-- <div v-if="checkKey(work.additionalInfo,'action')" class="flex">
																		<div class="font-semibold text-xs w-12">Approval :</div>
																		<div class="text-xs w-88 ">{{ work.additionalInfo.checkapproval }}</div>
																		<div v-for="(approval, number) in presentFindingTaskObj.approvals" :key="number" class="bg-white flex flex-row p-4 mb-0.5 border-lightgrey border-b w-full">
																			<div class="initials">{{extractInitials(getPersonName(work.additionalInfo.createdBy))}}</div>
																			<div class="font-semibold mt-1 text-xs">{{ getPersonName(work.additionalInfo.createdBy) }}</div>
																			<div v-if="work.additionalInfo.statusCode == 10401" class="mx-2 mt-1 text-xs"> {{' Requested an approval '}} </div>
																			<div v-else-if="work.additionalInfo.statusCode == 10410" class="mx-2 mt-1 text-xs"> {{' Rejected an approval '}} </div>
																			<div v-else-if="work.additionalInfo.statusCode == 10403" class="mx-2 mt-1 text-xs"> {{' Approved an approval '}} </div>
																			<div class="font-semibold mt-1 text-xs">{{work.additionalInfo.createdAt}}</div>
																		</div>
																	</div> -->
                                  <div class="flex ml-4">
                                      <div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorFindingBg(work.additionalInfo.previousStatus)">
                                          <div :class="colorFindingText(work.additionalInfo.previousStatus)">
                                              {{ getStatusLabel(work.additionalInfo.previousStatus) }}
                                          </div>
                                      </div>
                                      <div class="mx-4 flex items-center"><img src="@/assets/arrowRight.svg"/></div>
																			<div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorFindingBg(work.additionalInfo.updatedStatus)">
																				<div :class="colorFindingText(work.additionalInfo.updatedStatus)">
																					{{ getStatusLabel(work.additionalInfo.updatedStatus) }}
																				</div>
																			</div>
																		</div>
																	</div>
																	</div>

																
																<!-- <div v-if="Object.keys(work.additionalInfo).includes('evidence')" class="flex">
																	<div class="font-semibold text-xs w-12">Comment :</div>
																	<div class="text-xs w-88 ">{{ work.addtionalInfo.comment }}</div>
																</div>
																<div class="flex" v-if="Object.keys(work.additionalInfo).includes('evidence')">
																	<p class="font-semibold text-xs">{{'Uploaded the Evidence -'}}</p>
																	<p class="mx-2 text-xs">{{ work.additionalInfo.evidence }}</p>
																</div>
																
																<div class="flex" v-if="Object.keys(work.addtionalInfo).includes('assignee')">
																	<p class="font-semibold text-xs">{{'Assigned the Task To -'}}</p>
																	<p class="mx-2 text-xs">{{ getPersonName(work.addtionalInfo.assignee) }}</p>
																</div> -->
																
															</div>
														</div>
													</div>
												</div>
											</div>

										<!-- </div> -->
									</div>
								</div>
              </div>
            </div>
            <div
              class="w-25 border taskDetailsRightBar rounded-sm border-lightgrey"
            >
              <div class="m-3">
                <div class="pb-2 border-b border-lightgrey">
                  <h1>Task Details</h1>
                </div>
                <div class="pt-1.5 overflow-y-scroll thirtyWidthToggle">
                  <div class="flex flex-col">
                    <label class="controllabel">Task Status</label>
                    <div>
                      <div v-if="TaskDetailsDropDown.taskStatus == 10418" class="bg-error textColorWhite inputboxstyling1 cursor-pointer mt-2 mb-3">
                           <div class="bg-error textColorWhite">{{'Terminated'}}</div>
                      </div>
                      <!-- (newAssignee.value != userInfo._id && presentFindingTaskObj.teamOwner != userInfo._id) ||  -->
                      <select
                        class="mt-2 mb-3 inputboxstyling1 custom-select cursor-pointer w-full px-2.5 py-1 text-sm font-light text-gray rounded-sm border-solid border border-lightgrey"
                        :class="changeBackground()"
                        v-if="checkreviewer(presentFindingTaskObj.reviewer) &&
                          (TaskDetailsDropDown.taskStatus == 10419 ||
                            TaskDetailsDropDown.taskStatus == 10415 ||
                            TaskDetailsDropDown.taskStatus == 10403)"
                            
                        :disabled="TaskDetailsDropDown.taskStatus == 10403"
                        v-model="TaskDetailsDropDown.taskStatus"
                        @change="
                          updateTaskStatus(TaskDetailsDropDown.taskStatus)
                        "
                      >
                        <option value="" selected disabled hidden>
                          Please select one
                        </option>
                        <option
                          v-for="i in reviewerTaskData" 
                          :value="i.id"
                          :key="i"
                        >
                          {{ i.value }}
                        </option>
                      </select>
                      <!-- (newAssignee.value != userInfo._id  && presentFindingTaskObj.teamOwner != userInfo._id) ||  -->
                      <select
                        class="mt-2 mb-3 custom-select cursor-pointer w-full px-2.5 py-1 text-sm font-light text-gray rounded-sm border-solid border border-lightgrey"
                        :class="changeBackground()"
                        v-else-if="
                          !checkreviewer(presentFindingTaskObj.reviewer) &&
                          (TaskDetailsDropDown.taskStatus == 10401 ||
                            TaskDetailsDropDown.taskStatus == 10402 ||
                            TaskDetailsDropDown.taskStatus == 10408 ||
                            TaskDetailsDropDown.taskStatus == 10419)"
                        :disabled="
                          TaskDetailsDropDown.taskStatus == 10419 ||
                          TaskDetailsDropDown.taskStatus == 10415 ||
                          TaskDetailsDropDown.taskStatus == 10403
                        "
                        v-model="TaskDetailsDropDown.taskStatus"
                        @change="
                          updateTaskStatus(TaskDetailsDropDown.taskStatus)
                        "
                      >
                        <option value="" selected disabled hidden>
                          Please select one
                        </option>
                        <option
                          v-for="i in taskStatusData"
                          :value="i.id"
                          :key="i"
                        >
                          {{ i.value }}
                        </option>
                      </select>
                    </div>
                    <div>
                      <div
                        v-if="
                          !checkreviewer(presentFindingTaskObj.reviewer) &&
                          (presentFindingTaskObj.statusCode == 10415 ||
                            presentFindingTaskObj.statusCode == 10403)"
                        :class="changeBackground()"
                        class="inputboxstyling1 disabled cursor-pointer mt-2 mb-3"
                      >
                        <div
                          :class="changeBackground()"
                          @change="
                            updateTaskStatus(TaskDetailsDropDown.taskStatus)"
                        >
                          {{
                            reviewerTaskData.find((data) => {
                              return (
                                data.id == presentFindingTaskObj.statusCode
                              );
                            }).value
                          }}
                        </div>
                      </div>
                      <div
                        v-if="
                          checkreviewer(presentFindingTaskObj.reviewer) &&
                          (TaskDetailsDropDown.taskStatus == 10401 ||
                            TaskDetailsDropDown.taskStatus == 10402 ||
                            TaskDetailsDropDown.taskStatus == 10408)"
                        :class="changeBackground()"
                        class="inputboxstyling1 disabled cursor-pointer mt-2 mb-3"
                      >
                        <div
                          :class="changeBackground()"
                          @change="
                            updateTaskStatus(TaskDetailsDropDown.taskStatus)"
                        >
                          {{
                            taskStatusData.find((data) => {
                              return (
                                data.id == presentFindingTaskObj.statusCode
                              );
                            }).value
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label class="controllabel">Assigned To</label>
                      <div v-if="TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || presentFindingTaskObj.statusCode == 10403 || presentFindingTaskObj.statusCode == 10418 || presentFindingTaskObj.reviewer == userInfo._id  || (presentFindingTaskObj.teamOwner != userInfo._id && ( newAssignee.value != userInfo._id))">
                         <p class="mt-2"> {{ newAssignee.label}}</p>
                      </div>
                      <div v-else>
                            <multiselect
                                v-model="presentFindingTaskObj.assignee"
                                :options="userOptions"
                                :showNoOptions="false"
                                @search-change="asyncFind"
                                :multiple="false"
                                :close-on-select="true"
                                :clear-on-select="true"
                                label="label"
                                open-direction="bottom"
                                :hide-selected="true"
                                track-by="value"
                                @select="
                                  updateAssignedMember(presentFindingTaskObj.assignee)
                                "
                                :searchable="true"
                                :hideArrow="false"
                                placeholder="Assigned-To"
                                deselectLabel=""
                                selectLabel=""
                                class="custom-multiselect mt-2"
                              >
                        <template v-slot:noResult>
                          <span>No Members Found</span>
                        </template>
                      </multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <label class="controllabel">Reviewer</label><br />
                    <div v-if="presentFindingTaskObj.reviewer != undefined || presentFindingTaskObj.reviewer != null" class="mt-2 text-sm">
                      {{ getPerson(presentFindingTaskObj.reviewer) }}
                    </div>
                    <p class="mt-2 text-sm" v-else>N/A</p>
                  </div>
                  <div class="mt-3">
                    <label class="controllabel">Team Owner</label><br />
                    <div v-if="presentFindingTaskObj.teamOwner != undefined || presentFindingTaskObj.teamOwner != null" class="mt-2 text-sm">
                      {{ getPersonName(presentFindingTaskObj.teamOwner) }}
                    </div>
                    <p class="mt-2 text-sm" v-else>N/A</p>
                  </div>
                  <div class="mt-3">
                    <label class="controllabel">Team</label><br />
                    <div v-if="presentFindingTaskObj.department != undefined || presentFindingTaskObj.department != null" class="mt-2 text-sm">
                      {{ getTeamName(presentFindingTaskObj.department) }}
                    </div>
                    <p class="mt-2 text-sm" v-else>N/A</p>
                  </div>
                  <div class="mt-3">
                    <label class="controllabel">Entity</label><br />
                    <!-- <div v-if="presentFindingTaskObj.entitytypecodeid == 20303 && presentFindingTaskObj.entity != undefined || presentFindingTaskObj.entity != null" class="mt-2 text-sm">
                      {{ getLocationName(presentFindingTaskObj.entity) }}
                    </div>
                    <div class="mt-2" v-else-if="presentFindingTaskObj.entitytypecodeid == 20301">{{'Global'}}</div>
                    <p class="mt-2 text-sm" v-else>N/A</p> -->
                    <div v-if="presentFindingTaskObj.department != undefined || presentFindingTaskObj.department != null" class="mt-2 text-sm">{{ getDeptEntity(presentFindingTaskObj.department) }}</div>
                    <p class="mt-2 text-sm" v-else>N/A</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popup v-if="importpopUp" :popUp="importpopUp">
      <div
        class="overflow-x-hidden overflow-y-auto fixed inset-0 z-index justify-end items-start flex"
      >
        <div class="relative w-full max-w-xl">
          <!--content-->
          <div
            class="border-0 rounded relative flex flex-col w-full bg-white h-screen"
          >
            <!--header-->
            <div
              class="flex items-center justify-between popupheadingcontainer rounded"
            >
              <h1>Import files</h1>
              <button
                class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                @click="onCancelIcon()"
              >
                <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
              </button>
            </div>
            <div class="border-b border-solid border-lightgrey ml-4 mr-4"></div>
            <div class="impactcontainer popupbodycontainer">
              <div class="formcontainer flex flex-col">
                <div class="row flex flex-col mt-2">
                  <div>
                    <label class="controllabel">File Name </label>
                    <input
                      class="inputboxstyling mt-2"
                      type="text"
                      v-model="inputFileName"
                      placeholder="Filename"
                      :class="{ 'is-invalid': v$.inputFileName.$error }"
                    />
                    <div v-if="v$.inputFileName.$error" class="text-red-500">
                      <div
                        class="error-text"
                        v-if="v$.inputFileName.required.$invalid"
                      >
                        Required
                      </div>
                    </div>
                  </div>
                  <div class="pt-3">
                    <label class="controllabel">Attachment</label>
                    <div
                      class="mt-2 w-auto bg-white rounded-sm border-solid border border-lightgrey relative overflow-hidden"
                      :class="{ 'is-invalid': v$.fileToUpload.$error || pdfValidation}"
                    >
                      <!-- <label htmlFor="upload" class="controllabel">Upload File</label> -->
                      <input
                        type="file"
                        ref="fileInput"
                        class="hidden"
                        id="upload"
                        @change="handleFileUpload"
                      />
                      <label
                        for="upload"
                        class="float-right cursor-pointer text-white flex justify-center heightForDiv items-center w-9 bg-primary"
                        ><img src="@/assets/Browse.svg"
                      /></label>
                      <div v-if="fileName == ''" class="text-sm text-mediumGrey heightForDiv">
                        {{ placeholder }}
                      </div>
                       <div v-if="fileName != ''" class="text-sm heightForDiv">
                        {{ fileName }}
                      </div>
                    </div>
                  </div>

                  <div v-if="v$.fileToUpload.$error || pdfValidation" class="text-red-500">
                    <div v-if="v$.fileToUpload.$invalid && !pdfValidation" class="text-red-500 text-xs">
                      File upload is required
                    </div>
                    <div v-if="pdfValidation && !v$.fileToUpload.$invalid" class="text-red-500 text-xs">Upload only pdf</div>
                  </div>
                </div>
              </div>
            </div>
            <!--footer-->
            <div class="buttonposition flex justify-center items-center">
              <button
                class="btnprimary"
                :disabled="clickOnButtonAtAddingFile"
                @click="handleFileChange"
                :class="
                  inputFileName.length == 0
                    ? 'btndisabled pointer-events-none '
                    : 'btnprimary'"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </popup>
    <popup v-if="showApprovalPopup" :popUp="showApprovalPopup">
      <template v-slot:header>
        <div
          class="flex items-center justify-between popupheadingcontainer rounded"
        >
          <h1>Approval</h1>

          <button class="float-right text-3xl" @click="onCrossbtn()">
            <img src="@/assets/cancel.svg" class="cursor-pointer" />
          </button>
        </div>
      </template>

      <div class="popup-business">
        <div>
          <div>
            <div class="statuscontainer popupbodycontainer">
              <div class="formcontainer flex flex-col">
                <div class="row flex flex-row mt-2">
                  <div class="w-1/2">
                    <label class="controllabel">Name of the Authority</label>

                    <!-- <div class="inputboxstyling" disabled>
                      {{ getPersonName(presentFindingTaskObj.teamOwner) }}
                    </div> -->
                    <multiselect
                        v-model="taskObj.approver"
                        :options="userOptions"
                        :showNoOptions="false"
                        @search-change="asyncApproverFind"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        label="label"
                        open-direction="bottom"
                        :hide-selected="true"
                        track-by="value"
                        :searchable="true"
                        :hideArrow="false"
                        placeholder="Assigned-To"
                        deselectLabel=""
                        selectLabel=""
                        class="custom-multiselect mt-2"
                        :class="{ 'is-invalid borderRed': v$.taskObj.approver.$error }"
                      >
                        <template v-slot:noResult>
                          <span>No Members Found</span>
                        </template>
                      </multiselect>
                      <div v-if="v$.taskObj.approver.$error" class="text-red-500">
                        <div class="error-text" v-if="v$.taskObj.approver.required.$invalid">Required</div>
                      </div>
                  </div>
                </div>
                <div class="row flex flex-row upperspacing">
                  <div class="w-full">
                    <label class="controllabel"
                      >Justification or Comments for Approving</label
                    >
                    <textarea
                      class="inputboxstyling mt-2"
                      placeholder="Comments"
                      v-model="taskObj.approvalComments"
                      :class="{ 'is-invalid': v$.taskObj.approvalComments.$error }"
                    />
                    <div v-if="v$.taskObj.approvalComments.$error" class="text-red-500">
                      <div class="error-text" v-if="v$.taskObj.approvalComments.required.$invalid">Required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="buttonposition flex justify-center items-center mb-4 mx-0"
          >
            <button
              class="btnprimary mr-2"
              :class="
                !taskObj.approver && !taskObj.approvalComments
                  ? 'btndisabled pointer-events-none'
                  : 'btnprimary'
              "
              @click="onCancelbtnAtApproval()"
            >
              Cancel
            </button>
            <button
              class="btnprimary"
              :disabled="clickOnButton"
              :class="
                !taskObj.approver && !taskObj.approvalComments
                  ? 'btndisabled pointer-events-none'
                  : 'btnprimary'
              "
              @click="applyAuthority()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </popup>
    <popup v-if="showRejectPopupForNormalApproval" :popUp="showRejectPopupForNormalApproval">
      <template v-slot:header>
        <div
          class="flex items-center justify-between popupheadingcontainer rounded"
        >
          <h1>Justification to Reject an Approval</h1>

          <button class="float-right text-3xl" @click="onCrossBtnForNormalApproval()">
            <img src="@/assets/cancel.svg" class="cursor-pointer" />
          </button>
        </div>
      </template>

      <div class="popup-business">
        <div>
          <div>
            <div class="statuscontainer px-4 py-4">
              <div class="formcontainer flex flex-col w-full">
                    <label class="controllabel"
                      >Justification or Comments for Approving</label
                    >
                    <textarea
                      class="inputboxstyling mt-2"
                      placeholder="Comments"
                      v-model="normalJustification.justification"
                    />
              </div>
            </div>
          </div>
          <div
            class="buttonposition flex justify-center items-center mb-4 mx-0"
          >
            <button
              class="btnprimary mr-2"
              :class="
                normalJustification.justification == ''
                  ? 'btndisabled pointer-events-none'
                  : 'btnprimary'
              "
              @click="onCancelbtnForNormalApproval()"
            >
              Cancel
            </button>
            <button
             :disabled="clickOnButtonForNormal"
              class="btnprimary"
              :class="
                normalJustification.justification == ''
                  ? 'btndisabled pointer-events-none'
                  : 'btnprimary'
              "
              @click="ApprovalforInprogress(10410)"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </popup>
    <popup v-if="showRejectPopupAtInReview" :popUp="showRejectPopupAtInReview">
      <template v-slot:header>
        <div
          class="flex items-center justify-between popupheadingcontainer rounded"
        >
          <h1>Justification to Reject an Approval</h1>

          <button class="float-right text-3xl" @click="onCrossBtnForFinalApproval()">
            <img src="@/assets/cancel.svg" class="cursor-pointer" />
          </button>
        </div>
      </template>

      <div class="popup-business">
        <div>
          <div>
            <div class="statuscontainer px-4 py-4">
              <div class="formcontainer flex flex-col w-full">
                    <label class="controllabel"
                      >Justification or Comments for Approving</label
                    >
                    <textarea
                      class="inputboxstyling mt-2"
                      placeholder="Comments"
                      v-model="finalJustification.justification"
                    />
              </div>
            </div>
          </div>
          <div
            class="buttonposition flex justify-center items-center mb-4 mx-0"
          >
            <button
              class="btnprimary mr-2"
              :class="
                finalJustification.justification == ''
                  ? 'btndisabled pointer-events-none'
                  : 'btnprimary'
              "
              @click="onCancelbtnForFinalApproval()"
            >
              Cancel
            </button>
            <button
              :disabled="clickOnButtonForFinal"
              class="btnprimary"
              :class="
                finalJustification.justification == ''
                  ? 'btndisabled pointer-events-none'
                  : 'btnprimary'
              "
              @click="approveOrRejectForActionTasktoComplete(10401, 9)"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>
<style scoped>
.progressbarB {
  border: 2px solid #e9e9e9;
  background: #e9e9e9;
  position: relative;
  top: -8px;
  z-index: 1;
}
.numberBorder {
  border-top: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.numberNavy {
  border-left: 1px solid #152a43;
  border-left-width: 2px;
}
.progress {
  background: #152a43;
  position: relative;
  border-radius: 4px;
  z-index: 2;
}
.primaryBackground {
  background-color: #152a433b;
}
.descriptionToggle {
  height: 97%;
}
.tabStructure {
  height: calc(100vh - 165px);
}
.thirtyWidthToggle::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
  background: transparent;
}

.thirtyWidthToggle::-webkit-scrollbar-thumb {
  background: transparent;
}
.thirtyWidthToggle {
  height: calc(100vh - 250px);
}
.descriptionToggle::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
  background: transparent;
}
.fontSize10PX{
  font-size : 10px
}
.inputboxstyling1 {
  display: block;
  width: 100%;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgba(233, 233, 233, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  /* background-color: rgba(255, 255, 255, var(--tw-bg-opacity)); */
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 300;
  --tw-text-opacity: 1;
  /* color: rgba(77, 77, 77, var(--tw-text-opacity)); */
}
.descriptionToggle::-webkit-scrollbar-thumb {
  background: transparent;
}
.heightFixing {
  height: calc(100vh - 220px);
  /* height : 64vh; */
}
.w-12{
  width:12%;
}
.w-88{
  width:88%;
}
.paddingBottomForFindings {
  padding-bottom: 10.5px;
}
option {
  background-color: #ffffff;
  color: #000000;
  border-color: pink transparent transparent transparent;
}
.textColorWhite {
  color: white;
}
.custom-select {
  background-image: url("@/assets/Collapse.svg") !important;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: 12px 12px;
  background-position: right 15px center; /* Change the px value here to change the distance */
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.marginBottomForDoc {
  margin-bottom: 3px;
}
.marginTopForDoc {
  margin-top: 2.5px;
}
.marginBottomZero {
  margin-bottom: 0px;
}
.pendingEvidence {
  background-color: #8600f040;
}
.evidenceUpload {
  background: rgba(21, 39, 60, 0.24);
}
.labelWidth {
  min-width: 7rem;
}
.framework {
  padding: 12px 170px 12px 12px;
}
.findingCategory {
  padding: 12px 80px 12px 12px;
}
.paddingAdjustment{
  padding-bottom : 7px;
}
.heightForDiv{
  height : 29.333px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.pxMargin{
 margin-bottom : 1.5px;
}
.btndisabled{
  border :1px solid #e9e9e9
}
.btnprimary1 {
  cursor: pointer;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(21, 42, 67, var(--tw-bg-opacity));
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  font-family: Open Sans;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  line-height: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.borderRed {
  border: 1px solid red !important;
}
.primary{
	color:#152a43;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import breadCrumbs from "@/components/breadCrumb.vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import popup from "@/components/popup.vue";
import { groupBy } from "lodash";
import { toast } from "@/main";
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import Multiselect from "vue-multiselect";
// import loader from "../../components/loader.vue";
import commentsEditor from "@/components/commentsEditor.vue";
import { emitter } from '@/main';
import safeHtml from "@/components/safeHtml.vue";

export default defineComponent({
  data(): any {
    return {
      v$: useVuelidate(),
      fileToUpload: null,
      selectedTabIndex: 1,
      tabHead: [
        { displayName: "Description", id: 1 },

        { displayName: "Documents", id: 2 },

        { displayName: "Evidence", id: 3 },

        { displayName: "Comments", id: 4 },

        { displayName: "Approvals", id: 5 },

        { displayName: "History", id: 6 },
      ],
      routesArray: [],
      navIndexNums: { name: "", index: "" },
      selectedControlNumbers: [],
      TaskDetailsDropDown: {
        taskStatus: "",
        assigneeDropDown: "",
      },
      userOptions: [],
      terminateArray: [
        {
          id: 10418,
          status: "Terminated",
        },
      ],
      taskStatusData:  [
          {
            id: 10401,
            value: 'Open'
          },
          {
            id: 10402,
            value: 'In Progress'
          },
          {
            id: 10408,
            value: 'Waiting for Approval'
          },
          {
            id: 10419,
            value: 'ForReview'
          },
          ],
      reviewerTaskData: [
        {
          id: 10419,
          value: "ForReview",
        },
        {
          id: 10415,
          value: "InReview",
        },
        {
          id: 10403,
          value: "Completed",
        },
      ],
      findingCategoryData: [
        {
          id: 1,
          value: "Minor",
        },
        {
          id: 2,
          value: "Major",
        },
        {
          id: 3,
          value: "OFI",
        },
        {
          id: 4,
          value: "Observation",
        },
      ],

      assigneeData: [
        {
          id: 1,
          name: "Nikhil Kapuganti",
        },
        {
          id: 2,
          name: "Abhiram Kadari",
        },
        {
          id: 3,
          name: "Venkatesh Abisetty",
        },
      ],
      oldComments: true,
      newHistory: true,
      comment: "",
      allEntities: [],
      comments: [],
      btnDisabled: true,
      showPopUp: false,
      findingsObject: {
        findingId: "",
        frameworks: "",
        frameWorkControl: "",
        findingCategory: "",
        findingComment: "",
        findingEvidence: "",
        auditResult: "",
      },
      taskObj: {},
      findingsObjectDummy: {},
      presentAuditData: {},
      selectedOption: "",
      selectedBG: "",
      workLogObject: [],
      optionsUserArray: [],
      orgUsersList: [],
      presentComplianceObject: {},
      tasksForAudit: [],
      presentTaskObject: {},
      personObj: "",
      controlsArray: [],
      controlDetailsArray: [],
      controlsArrayAllControls: [],
      controlsDetailsList: [],
      teamObj: "",
      selectedTabObj: {},
      users: [],
      allFrameWorkControls: [],
      locationObject: [],
      importpopUp: false,
      isInputFileNameValid: true,
      fileSelected: false,
      isFileValid: true,
      inputFileName: "",
      fileChanged: false,
      fileName: "",
      findingsForTask: [],
      allTasks: [],
      presentFindingTaskObj: {},
      showApprovalPopup: false,
      approvalObj: {},
      enableApproveAndReject : false,
      showRejectPopupForNormalApproval : false,
      showRejectPopupAtInReview : false,
      normalJustification:{
        justification : ''
      },
      finalJustification:{
        justification : ''
      },
      displayFormalapprovalButtons : false,
      showLoader: false,
      pdfValidation: false,
      clickOnButton: false,
      clickOnButtonAtAddingFile: false,
      clickOnButtonForNormal : false,
      clickOnButtonForFinal : false,
      newAssignee : '',
      placeholder: 'Select a File',
    };
  },
  validations() {
    return {
      inputFileName: { required },
      fileToUpload: { required },
      taskObj: {
        approver: {required},
        approvalComments: {required}
      },
      findingsObject: {
        findingId: { required },
        frameworks: { required },
        frameWorkControl: { required },
        findingCategory: { required },
        findingComment: { required },
        findingEvidence: { required },
        // auditResult: { required }
      },
    };
  },
  components: {
    breadCrumbs,
    popup,
    Multiselect,
    // loader,
    commentsEditor,
    safeHtml
  },
  computed: {
    ...mapGetters({ userInfo: "userInfo" }),
  },
  methods: {
    filterTaskStatusData(data: any) {
      const startId = 10419;
      const endId = 10403;
      return data.filter((item: any) => item.id >= startId && item.id <= endId);
    },
    asyncFind(query: any) {
      if (query == "") {
        this.userOptions = [];
      } else if (query.length > 1) {
        this.userOptions = this.optionsUserArray.filter((obj: any) => {
          if(obj.value != this.presentFindingTaskObj.reviewer){
            return obj.label.toLowerCase().includes(query.toLowerCase());
          }
        });
      }
    },
    asyncApproverFind(query: any) {
      if (query == "") {
        this.userOptions = [];
      } else if (query.length > 1) {
        this.userOptions = this.optionsUserArray.filter((obj: any) => {
          if(obj.value != this.presentFindingTaskObj.reviewer && obj.value != this.userInfo._id){
            return obj.label.toLowerCase().includes(query.toLowerCase());
          }
        });
      }
    },
    openApprovalpopup() {
      this.showApprovalPopup = true;
    },
    onCrossbtn() {
      this.showApprovalPopup = false;
      // this.taskObj.approvalComments = '';
    },
    clickToRejectByJustification(){
      this.showRejectPopupForNormalApproval = true;
    },
    onClickToRejectByJustificationAtInReview(){
      this.showRejectPopupAtInReview = true;
    },
    onCrossBtnForNormalApproval(){
      this.showRejectPopupForNormalApproval = false;
      this.normalJustification.justification = '';
    },
    onCrossBtnForFinalApproval(){
      this.showRejectPopupAtInReview = false;
      this.finalJustification.justification = '';
    },
    onCancelbtnForNormalApproval(){
      this.onCrossBtnForNormalApproval();
    },
    onCancelbtnForFinalApproval(){
      this.onCrossBtnForFinalApproval();
    },
    async ApprovalforInprogress(statusCode: any) {
      this.clickOnButtonForNormal = true;
      const payload: any = {
        statusCode: statusCode,
        updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        updatedBy: this.userInfo._id,
      };
     try{
       await this.$http
        .post(
          `${process.env.VUE_APP_MONITOR_API_URL}/api/org/control/task/${this.$route.params.taskId}/update/approval`,
          payload
        )
        .then((result: { data: any }) => {
			if (statusCode == 10403) {
				toast.info(`Approval Approved`, {
				timeout: 1000,
				closeOnClick: true,
				closeButton: "button",
				icon: true,
				})

			} else if(statusCode == 10410){
				toast.info(`Approval Rejected`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: "button",
					icon: true
				});
			}
      this.enableApproveAndReject = false
        });
     }catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			} finally {
				this.clickOnButtonForNormal = false;
			}
        await this.getAllFindingTasks();
        await this.getFindingTaskHistoryObject()
        this.onCrossBtnForNormalApproval()
    },
    onCancelbtnAtApproval() {
      // this.showModal = false;
			this.v$.$reset();
			this.showBlock = false;
      this.taskObj = {}
		},
    async applyAuthority() {
      this.v$.$touch();
			if (!this.v$.taskObj.$invalid) {
        this.clickOnButton = true;
        let dummyTaskObj:any = _.cloneDeep(this.taskObj);
        this.enableApproveAndReject = true
        this.showApprovalPopup = false;
        const payload: any = {
          approver: dummyTaskObj.approver.value ? dummyTaskObj.approver.value: dummyTaskObj.approver,
          comments: dummyTaskObj.approvalComments
        };
        // 			{
        //     "assignee": 64ca27ca3c8058400c9f7a68,
        //     "comments": "justification message",
        //     "created By": "64ca27ca3c8058400c9f7a68",
        //     "createdAt": "2024-07-03 12:17:46"
        // }
        await this.updateFindingTask(8, payload);
        await this.getAllFindingTasks();
        await this.getFindingTaskHistoryObject()
        dummyTaskObj = {};
      }
    },
    checkKey(obj: any, key: any) {
      if (obj == "" || obj == undefined) {
        return false;
      } else {
        const keys: any = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
          if (keys[i] === key) {
            return true;
          }
        }
        return false;
      }
    },
    async approveOrRejectForActionTasktoComplete(statusCode: any, typeId: any) {
      this.clickOnButtonForFinal = true;
      const payload: any = {
        action: 10302,
        statusCode: statusCode,
        createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        createdBy: this.userInfo._id,
      };
      try{
        await this.$http
        .post(
          `${process.env.VUE_APP_MONITOR_API_URL}/task/${this.$route.params.taskId}/type/${typeId}/update`,
          payload
        )
        .then((result: { data: any }) => {
        });
      if (statusCode == 10403) {
        toast.info(`Approval Approved`, {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
        // const workflowpayload = [
        //   {
        //     findingId: this.$route.params.findingId,
        //     createdBy: this.userInfo._id,
        //     createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        //   },
        // ];
        // await this.$http
        //   .post(
        //     `${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/compliance/findings/task`,
        //     workflowpayload
        //   )
        //   .then((result: { data: any }) => {
        //   });
      } else if (statusCode == 10401) {
        toast.info(`Approval Rejected`, {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }
      }catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			} finally {
				this.clickOnButtonForFinal = false;
			}
      await this.getFindingTaskHistoryObject();
      await this.getAllFindingTasks();
      this.onCrossBtnForFinalApproval();
    },
    async getFindingTaskHistoryObject() {
      let taskId = this.$route.params.taskId;
      // /api/org/task/:taskId/getHistory
      await this.$http
        .get(
          `${process.env.VUE_APP_MONITOR_API_URL}/api/org/task/${taskId}/getHistory`
        )
        .then((result: any) => {
          const mainObject = result.data;
          this.workLogObject = mainObject;
        });
        this.workLogObject.taskHistory.sort().reverse();
    },

    getCategory(num: any) {
      let result = this.findingCategoryData.find((obj: any) => {
        return obj.id == num;
      });
      return result.value;
    },
    getDeptEntity(teamId: any) {
      let presentteamObject: any = this.users.find((obj: any) => {
        return obj._id == teamId;
      });
      if (presentteamObject != undefined && presentteamObject.entityType == 20301) {
        // let entityObj = this.allEntities.find((entity: any)=>{return entity.entityType == 1})
        return presentteamObject ? presentteamObject.entityName : 'N/A';
      } else if(presentteamObject != undefined && presentteamObject.entityType == 20303){
        return presentteamObject ? presentteamObject.entityDisplayName : 'N/A';
      } else {
        return 'N/A'
      }
    },
    getLocation(teamId: any) {
      let presentteamObject: any = this.users.find((obj: any) => {
        return obj._id == teamId;
      });

      if (presentteamObject != undefined && presentteamObject.type == 20301) {
        let entityObj = this.allEntities.find((entity: any) => {
          return entity.entityType == 1;
        });
        return entityObj ? entityObj.entityName : 'N/A';
      } else {
        if (
          this.presentTaskObject.location == undefined ||
          this.presentTaskObject.location == null
        ) {
          return "N/A";
        } else {
          return this.getLocationName(
            this.presentTaskObject.location[0].entity
          );
        }
      }
    },
    getLocationName(locationId: any) {
      if(locationId == 'global'){
        return 'Global'
      }else if (locationId == null){ return 'N/A'}
      else{
          let locationObj = this.locationObject.find((obj: any) => {
            return obj.value == locationId;
          });
          if (locationObj == undefined) {
            let empty = "";
            return empty;
          } else {
            return locationObj.label;
          }
      }
    },
    onCancelIcon() {
      this.importpopUp = false;
      this.inputFileName = "";
      this.fileName = "";
      this.pdfValidation = false;
      this.v$.$reset();
    },
    getStatusLabel(num: any) {
      // let result = this.taskStatusData.find((obj: any) => {
      //   return obj.id == num;
      // });
      // if (result != null || result != undefined) {
      //   return result.value;
      // }
      if(num == 10415 || num == 10403){
          let result = this.reviewerTaskData.find((obj:any)=>{return obj.id == num})
          if(result != null || result != undefined){
              return result.value
          }
      }
			let result = this.taskStatusData.find((obj:any)=>{return obj.id == num})
			if(result != null || result != undefined){
          return result.value
      }
    },
    scrollTabIntoView(tabId: any) {
      this.$nextTick(() => {
        const tabElement = this.$el.querySelector(`.tab:nth-child(${tabId})`);
        if (tabElement) {
          tabElement.scrollIntoView({ behavior: "smooth", inline: "center" });
        }
      });
    },
    getTeamName(team: any) {
      let teamObj = this.users.find((obj: any) => {
        return obj._id == team;
      });
      if (teamObj == undefined) {
        return "";
      } else {
        return teamObj.teamName;
      }
    },
    colorFindingBg(status: any) {
            if (status == 10401) {
                return ['bg-lightgrey border-solid border border-disabledgrey'];
            } else if (status == 10415) {
                return ['bg-moderateColor border-solid border border-yellow'];
            } else if (status == 10403) {
                return ['bg-veryLowColor border-solid border border-lightgreen'];
            } else if (status == 10414) {
                return ['pendingEvidence border-solid border border-electricGreen'];
            } else if (status == 10402 ) {
                return ['pendingEvidence border-solid border border-electricGreen'];
            } else if (status == 10408) {
                return ['evidenceUpload border-solid border border-primary'];
            } else if (status == 10419) {
                return ['bg-forReviewBg border-solid border border-forReview'];
            }
        },
        colorFindingText(status: any) {
            if (status == 10401) {
                return ['font-semibold text-xs text-disabledgrey'];
            } else if (status == 10415 ) {
                return ['font-semibold text-xs text-yellow'];
            } else if (status == 10403) {
                return ['font-semibold text-xs text-lightgreen'];
            } else if(status == 10414){
                return ['font-semibold text-xs mx-2 text-electricGreen']
            } else if (status == 10402 ) {
                return ['font-semibold text-xs text-electricGreen'];
            } else if(status == 10408){
                return ['font-semibold text-xs mx-2 text-primary']
            } else if(status == 10419){
				        return ['font-semibold text-xs mx-2 text-forReview']
			}
        },
      checkreviewer() {
      if (this.presentFindingTaskObj.reviewer == this.userInfo._id) {
        return true;
      } else if (
        this.presentFindingTaskObj.reviewer == undefined ||
        this.presentFindingTaskObj.reviewer == null
      ) {
        return "-";
      } else {
        return false;
      }
    },
    async getallUsers() {
      await this.$http
        .get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`)
        .then((res: any) => {
          this.orgUsersList = res.data;
          for (var i = 0; i < this.orgUsersList.length; i++) {
            // if (this.orgUsersList[i].isActive == true) {
              this.optionsUserArray.push({
                label: this.orgUsersList[i].name,
                value: this.orgUsersList[i]._id,
              });
            // }
          }
        });
    },
    async getallEntities() {
      this.locationObject = [];
      let result: any;
      await this.$http
        .get(
          `${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`
        )
        .then((res: any) => {
          result = res.data;
          this.allEntities = res.data;
          console.log("allEntities", this.allEntities)
        });
      for (var i = 0; i < result.length; i++) {
        this.locationObject.push({
          label: result[i].entityName,
          value: result[i]._id,
        });
      }
    },
    async getAllTeams() {
      this.teamObject = [];
      await this.$http
        .get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`)
        .then((res: any) => {
          this.users = res.data;
        });
      for (var i = 0; i < this.users.length; i++) {
        this.teamObject.push({
          label: this.users[i].teamName,
          value: this.users[i]._id,
        });
      }
    },
    getPersonName(person: any) {
      let personObj = this.orgUsersList.find((user: any) => {
        return user._id == person;
      });
      if (personObj == undefined) {
        return "";
      } else {
        return personObj.name;
      }
    },
    getPerson(person: any) {
      this.personObj = this.orgUsersList.find((user: any) => {
        return user._id == person;
      });
      if (this.personObj == undefined) {
        return "";
      } else {
        return this.personObj.name;
      }
    },
    sortWorkLog() {
      this.newHistory = !this.newHistory;
      this.workLogObject.taskHistory.sort().reverse();
    },
    sortComments() {
      this.oldComments = !this.oldComments;
      if(this.presentFindingTaskObj.comments != undefined && this.presentFindingTaskObj.comments.length != 0){
        this.presentFindingTaskObj.comments.sort().reverse();
      }
    },
    formatTime(time: any) {
      let now = moment(time);
      return now.format("DD-MM-YYYY, hh:mm:ss");
    },
    extractInitials(name: any) {
      const names = name.split(" ");
      return names
        .map((name: any) => name[0])
        .join("")
        .toUpperCase();
    },
    changeBackground() {
      if (this.TaskDetailsDropDown.taskStatus == 10401) {
        return "bg-disabledgrey textColorWhite";
      }else if (this.TaskDetailsDropDown.taskStatus == 10418) {
        return "bg-error textColorWhite";
      }   else if (this.TaskDetailsDropDown.taskStatus == 10415) {
        return "bg-yellow textColorWhite";
      } else if (this.TaskDetailsDropDown.taskStatus == 10402) {
        return "bg-electricGreen textColorWhite";
      } else if (this.TaskDetailsDropDown.taskStatus == 10408) {
        return "bg-primary textColorWhite";
      } else if (this.TaskDetailsDropDown.taskStatus == 10403) {
        return "bg-lightgreen textColorWhite";
      } else if (this.TaskDetailsDropDown.taskStatus == 10417) {
        return "bg-darkred textColorWhite";
      }else if (this.TaskDetailsDropDown.taskStatus == 10419) {
        return "bg-forReview textColorWhite";
      } else {
        return "";
      }
    },
    async updateTaskStatus(status: any) {
      let payload: any = { statusCode: status };
      await this.updateFindingTask(4, payload);
    },
    async updateAssignedMember(obj: any) {
      let payload: any = { assignee: obj.value };
      await this.updateFindingTask(6, payload);
      if(this.presentFindingTaskObj.assignee != undefined ){
        this.newAssignee = this.presentFindingTaskObj.assignee
      }
    },
    handleInput() {
      this.btnDisabled = this.comment.length === 0;
    },
    async addComment() {
      if (this.comment.trim() !== "") {
        const newComment: any = {
          comment: this.comment,
        };
        await this.updateFindingTask(1, newComment);
        // if (this.presentFindingTaskObj.comments != undefined) {
        //   this.presentFindingTaskObj.comments.sort().reverse();
        // }
        // this.comments.push(newComment);
        this.comment = "";
        this.btnDisabled = true;
      }
    },
    clearComment() {
      this.comment = "";
      this.btnDisabled = true;
    },
    async toggleTabs(obj: any) {
      // { displayName: 'Description', id: 1 },

      // 	{ displayName: 'Related Documents', id: 2 },

      // 	{ displayName: 'Evidence', id: 3 },

      // 	{ displayName: 'Comments', id: 4 },

      // 	{ displayName: 'Approvals', id: 5 },

      // 	{ displayName: 'Work History', id: 6 }
      // ],
      // this.scrollTabIntoView(obj.id);
      // this.selectedTabIndex = obj.id;
      // this.selectedTabObj = obj;
      this.selectedTabIndex = obj.id;
      this.selectedTabObj = obj;
      sessionStorage.setItem('selectedTab', obj.id);
     
    },
    showFindingPopup() {
      this.showPopUp = !this.showPopUp;
    },
    onCancelButtonAddPopUp() {
      this.showPopUp = false;
      this.onCancelbtn();
    },
    openFilePopup() {
      // this.$refs.fileInput.click();
      this.pdfValidation = false;
      this.importpopUp = true;
    },

    handleFileUpload(event: any) {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        this.fileName = file.name;
        this.fileToUpload = file;
        const fileNameParts = file.name.split(".");
        fileNameParts.pop(); // Remove the extension
        const nameWithoutExtension = fileNameParts.join(".");
        if (this.inputFileName.trim() === "") {
          this.inputFileName = nameWithoutExtension;
        }
        this.pdfValidation = false;
      }else {
        this.fileName = "";
        this.inputFileName = "";
      }
      let fileExtension = file.name.split(".").pop();
      if (fileExtension == "pdf") {
        this.pdfValidation = false;
        this.v$.$reset();
      } else {
        this.pdfValidation = true
      }
    },
    async handleFileChange(event: any) {
      let data = this.$refs.fileInput.files[0];
      // this.fileToUpload = data
      this.v$.inputFileName.$touch()
      this.v$.fileToUpload.$touch();
      if (((!this.v$.fileToUpload.$invalid) && (!this.v$.inputFileName.$invalid) && !this.pdfValidation)) {
        this.clickOnButtonAtAddingFile = true;
        let fileExtension = data.name.split(".").pop();
        // if (fileExtension == "pdf") {
          this.importpopUp = !this.importpopUp;
          const fileDataFormat = await this.getConvertDataForFile(data);
          let uuid = uuidv4();
          let payload = {
            fileData: fileDataFormat,
            name: `${uuid}.${fileExtension}`,
          };
          let folderName = "finding";
          try {
            await this.$http
              .post(
                `${process.env.VUE_APP_FILE_API_URL}/bucket/${folderName}/file/upload`,
                payload
              )
              // .post(`http://localhost:4030/services/filemanagement/bucket/${folderName}/file/upload`, payload)
              // bucket/:folderName/file/upload
              .then(async (response: any) => {
                if (response.status == 200) {
                  let payload: any = {
                    name: `${uuid}.${fileExtension}`,
                    url: response.data,
                    filename: this.inputFileName,
                  };
                  await this.updateFindingTask(3, payload);
                  this.inputFileName = "";
                  this.fileName = "";
                  this.v$.$reset();
                } else {
                  console.log("response status");
                }
              });
          } catch (err) {
            console.error(err);
          } finally {
            this.clickOnButtonAtAddingFile = false;
          }
        // } else {
          // toast.error(`Upload only pdf`, {
          //   timeout: 1000,
          //   closeOnClick: true,
          //   closeButton: "button",
          //   icon: true,
          // });
        // }
        // this.inputFileName = "";
        // this.fileName = '';
			  // this.fileToUpload=null
      }
    },
    async updateFindingTask(type: any, payload: any) {
      payload.createdBy = this.userInfo._id;
      payload.createdAt = moment().format("YYYY-MM-DD HH:mm:ss");
      if (type == 1) {
		// comment
        payload.additionalInfo = { comment: "" };
        payload.additionalInfo.comment = payload.comment;
      } else if (type == 3) {
		// files
        payload.additionalInfo = { evidence: "" };
        payload.additionalInfo.evidence = payload.filename;
      } else if (type == 4) {
		// statusCode
        payload.additionalInfo = { previousStatus: "", updatedStatus: "" };
        payload.additionalInfo.previousStatus = this.presentFindingTaskObj.statusCode;
        payload.additionalInfo.updatedStatus = payload.statusCode;
      } else if (type == 6) {
		// assignee
        payload.additionalInfo = { assignee: "" };
        payload.additionalInfo.assignee = payload.assignee;
      } else if (type == 8) {
		// approver
		payload.createdBy = this.userInfo._id;
      	payload.createdAt = moment().format("YYYY-MM-DD HH:mm:ss");
		// payload.approver = payload.approver;
        // payload.comments = payload.comments;
      }
      try {
        await this.$http
          .post(
            `${process.env.VUE_APP_MONITOR_API_URL}/task/${this.$route.params.taskId}/type/${type}/update`,
            payload
          )
          .then(async (result: any) => {
            toast.info(`Updated`, {
              timeout: 1000,
              closeOnClick: true,
              closeButton: "button",
              icon: true,
            });
            await this.getAllFindingTasks();
            await this.getFindingTaskHistoryObject();
          });
      } catch {
        toast.error(`Error`, {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }finally {
				this.clickOnButton = false;
			}
    },
    async getConvertDataForFile(data: any) {
      return new Promise((resolve) => {
        const reader: any = new FileReader();
        reader.onload = function () {
          resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
        };
        reader.readAsDataURL(data);
      });
    },
    colorAuditText(status: any) {
      if (status == 10401) {
        return ["font-semibold text-disabledgrey"];
      } else if (status == 10415) {
        return ["font-semibold text-yellow"];
      } else if (status == 10403) {
        return ["font-semibold text-lightgreen"];
      } else if (status == 10402) {
        return ["font-semibold mx-2 text-electricGreen"];
      } else if (status == 10408) {
        return ["font-semibold mx-2 text-primary"];
      } else if (status == 10417) {
        return ["font-semibold mx-2 text-darkred"];
      }
    },
    colorAuditBg(status: any) {
      if (status == 10401) {
        return ["bg-lightgrey border-solid border border-disabledgrey"];
      } else if (status == 10415) {
        return ["bg-moderateColor border-solid border border-yellow"];
      } else if (status == 10403) {
        return ["bg-veryLowColor border-solid border border-lightgreen"];
      } else if (status == 10402) {
        return ["pendingEvidence border-solid border border-electricGreen"];
      } else if (status == 10408) {
        return ["evidenceUpload border-solid border border-primary"];
      } else if (status == 10417) {
        return ["bg-veryHighColor border-solid border border-darkred"];
      }
    },
    async getAllFindingTasks() {
      this.showLoader = true;
      await this.$http
        .get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/tasks/getall`)
        .then((result: any) => {
          this.allTasks = result.data;
          this.showLoader = false;
          this.presentFindingTaskObj = this.allTasks.find(
            (task: any) => task.actionType === 2 && task._id == this.$route.params.taskId
          );
          if (this.presentFindingTaskObj.comments != undefined && this.presentFindingTaskObj.comments.length != 0) {
            let commentsArray: any = [];
              this.presentFindingTaskObj.comments.forEach((commentObj: any)=>{
                commentObj.comment = atob(commentObj.comment)
                commentsArray.push(commentObj);
              })
              this.presentFindingTaskObj.comments = commentsArray
            
            this.presentFindingTaskObj.comments.sort().reverse();
          }
          let taskId: any = this.$route.params.taskId;
          let matchedTask: any = this.allTasks.find(
            (task: any) => task._id == taskId
          );
          if (matchedTask) {
            this.presentFindingTaskObj = matchedTask;
          } else {
            this.presentFindingTaskObj = null;
          }
          if (
            this.presentFindingTaskObj.approvals != null ||
            this.presentFindingTaskObj.approvals != undefined
          ) {
            const lastApproval =
              this.presentFindingTaskObj.approvals[
                this.presentFindingTaskObj.approvals.length - 1
              ];
            this.approvalObj = lastApproval;
          } else {
            this.approvalObj = {};
          }
          this.TaskDetailsDropDown.taskStatus =
            this.presentFindingTaskObj.statusCode;
          if (this.presentFindingTaskObj.assignee != undefined || this.presentFindingTaskObj.assignee != null ) {
            let userObj: any = this.optionsUserArray.find((obj: any) => {
              return obj.value == this.presentFindingTaskObj.assignee;
            });
            this.presentFindingTaskObj.assignee = userObj;
          } else {
            let userObj: any = this.optionsUserArray.find((obj: any) => {
              return obj.value == this.presentFindingTaskObj.teamOwner;
            });
            this.presentFindingTaskObj.assignee = userObj;
          }
          // console.log("this.presentFindingTaskObj", this.presentFindingTaskObj)
          // if (this.presentFindingTaskObj.location != 'Global') {
          //   let location
          //   if(this.allEntities != undefined || this.allEntities.length != 0){
          //     location = this.allEntities.find((loc: any)=>{
          //       return loc._id == this.presentFindingTaskObj.location
          //     })
          //   }
          //   // this.presentFindingTaskObj.location = location != undefined ? location.entityName : 'N/A'
          // } else if(this.presentFindingTaskObj.location == 'Global'){
          //   let location
          //   if(this.allEntities != undefined || this.allEntities.length != 0){
          //     location = this.allEntities.find((loc: any)=>{
          //       return loc.entityType == 1
          //     })
          //   }
          //   this.presentFindingTaskObj.location = location != undefined ? location.entityName : 'N/A'
          // }
        })
        .catch((error: any) => {
          console.error("Error fetching tasks:", error);
          this.showLoader = false
        });
    },
  },
  async mounted(){
        emitter.off('addedComments');
        emitter.on('addedComments',(data: any)=>{
            this.comment = data.comments
            this.addComment()
        })
       
    },
  beforeUnmount() {
		 sessionStorage.removeItem('selectedTab')
	 },
  async created() {
    this.showLoader = true;
     const savedTabKey = sessionStorage.getItem('selectedTab');
		  if (savedTabKey) {
        const savedTab = this.tabHead.find((tab: any) => tab.id == savedTabKey);
        if (savedTab) {
			      this.toggleTabs(savedTab);
        }
   		else{
         this.selectedTabObj = { displayName: "Description", id: 1 };
	  	}
    }
    else{
	   this.selectedTabObj = { displayName: "Description", id: 1 };
	 }
    await this.getallUsers();
    await this.getAllTeams();
    await this.getallEntities();
    await this.getAllFindingTasks();
    await this.getFindingTaskHistoryObject();
    // let taskId:any = this.$route.params.taskId;
    // let obj : any = this.presentFindingTaskObj.find((task: any)=>{return task._id == taskId})
    // this.presentFindingTaskObj = obj
    // this.selectedTabIndex = 1;
    this.findingsObjectDummy = { ...this.findingsObject };
    this.routesArray = [
      { name: "My Findings Task", routeName: "findingTasks" },
      { name: this.presentFindingTaskObj.taskId, routeName: "actionTask" },
    ];
    this.navIndexNums = { index: 1, name: "Description" };
    if(this.presentFindingTaskObj.assignee != undefined ){
      this.newAssignee = this.presentFindingTaskObj.assignee
    }
  },
});
</script>