<template>
	<div class="pt-3 pl-5 pr-5">
		<div class="row w-full mb-3 justify-between flex" style="align-items: center">
			<h1 class="title">Tenant Frameworks</h1>
			<div class="cardbody">
				<div class="flex flex-row-reverse items-center">
					<button class="flex" :class="tableRow.length == 0 ? 'filterButtondisabled pointer-events-none' : 'filterButton'" @click="filterOpen">
						Filters
						<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
					</button>
				</div>
			</div>
		</div>
		<div class="mt-3 text-xs">
			Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
			velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
		</div>
		<tableData :tableRowData="tableRow" :headersData="headers" :loadData="dataLoading"></tableData>
	</div>
</template>
<style scoped>
.btndisabled {
  @apply bg-lightgrey font-opensans font-bold text-sm px-2.5 py-2.5 text-gray leading-3 rounded
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import tableData from '@/components/tableData.vue';
import { emitter, toast } from '@/main';

export default defineComponent({
	components: {
		tableData
	},
	data(): any {
		return {
			headers:['_id','tenantName','frameWorks'],
			tenantsSelect: [],
			tableRow: [],
			showFilters: false,
			dataLoading:true,
		};
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' })
	},
	methods: {
		async getAllTenants() {
			await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
				res.data.map(async (org: any) => {
					let object = { value: org._id, label: org.organizationName };
					this.tenantsSelect.push(object);

					let tableObj = { _id: org._id, tenantName: org.organizationName };
					await this.getTenantFrameWorks(org._id, tableObj);
				});
			});
		},
		async getTenantFrameWorks(tenantId: any, tableObj: any) {
			this.dataLoading = true;
			await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/tenant/${tenantId}/controls/get`).then((res: any) => {
				let tenantFrameWorks = res.data;
				tableObj.frameWorks = tenantFrameWorks.length;
				this.tableRow.push(tableObj);
			});
			this.dataLoading = false;
		},
		filterOpen() {
			this.showFilters = !this.showFilters;
			emitter.emit('visible', this.showFilters);
		},
		groupBy(arr: any, key: any) {
			let groupBy = arr.reduce((group: any, product: any) => {
				const keyValue = product[key];
				group[keyValue] = group[keyValue] ?? [];
				group[keyValue].push(product);
				return group;
			}, {});
			return groupBy;
		}
	},
	async mounted() {
		this.dataLoading = true;
		await this.getAllTenants();
		emitter.on('tenenatFrameWorks', (isData: any) => {
			console.log('data', isData);
			this.$router.push({ name: 'tenantControls', params: { tenantId: isData._id } });
		});
		// this.dataLoading = false;
	}
});
</script>

