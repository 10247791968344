<template>
  <div class="pt-3 pl-5 pr-5">
    <!-- <div class="mb-2">
        <breadCrumb :routesArray="routesArray"></breadCrumb>
    </div> -->
    <div v-if="isLoading">
      <div
        class="row w-full mb-3 justify-between flex"
        style="align-items: center"
      >
        <h1 class="title">Findings</h1>
        <div class="cardbody">
          <div class="flex flex-row-reverse items-center">
            <button
              class="flex"
              @click="filterOpen"
              :class="tableRow.length == 0 ? 'filterButtondisabled pointer-events-none' : 'filterButton'"
            >
              Filters
              <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
            </button>
          </div>
        </div>
      </div>
      <div>
        <tableData
          :tableRowData="tableRow"
          :headersData="headers"
          :departmentArr="deptOptions"
          :locations="locationObject"
          :loadData="dataLoading"
        ></tableData>
        <div v-if="toolTipActive">
          <tool-tip :tipText="toolTipText"></tool-tip>
        </div>
      </div>
    </div>
    <!-- <loader v-if="showLoader" /> -->
  </div>
</template>
<style scoped>
.multiselect-tag {
  font-size: 12px;
  font-weight: 600;
}
.multiselect-tag.is-disabled {
  background-color: #059669 !important;
}
.multiselect.is-disabled {
  background-color: white !important;
}
</style>
<script lang="ts">
import * as XLSX from 'xlsx';
// import loader from "../../components/loader.vue";
import _ from "lodash";
import { defineComponent } from "vue";
import { emitter, toast } from "@/main";
import popup from "@/components/popup.vue";
import tableData from "@/components/tableData.vue";
import breadCrumb from '@/components/breadCrumb.vue';
import toolTip from '@/components/toolTip.vue';
// import Multiselect from '@vueform/multiselect/src/Multiselect';
export default defineComponent({
  data(): any {
    return {
      headers: ['findingId','finding','compliance Title', 'createdAt','statusCode'],

      isLoading: false,
      showLoader: false,

      showFilters: false,
      hideFormula: false,
      showPopup: false,
      columnObject: false,

      dummyList: [],
      allKpisList: [],
      toolTipActive : false,
			toolTipText : '',
      actionBtn:true,
      routesArray: [],
      tableRow: [],
      departmentsList: [],
      presentCompliance: {},
      optionsValue: [
          {
              id: 1,
              value: 'View',
          }
      ],
      locationObject: [],
      controlDepartmentArr: [],
      internalControlsDetailsArray: [],
      naDepartments: "",

      kpiList: [],
      deptOptions: [],
      allKpis: {
        kpi_id: "",
        weightage: "",
        description: [],
      },
    //   findingCategoryData: [
    //     {
    //         id: 1,
    //         value: 'Minor'
    //     },
    //     {
    //         id: 2,
    //         value: 'Major'
    //     },
    //     {
    //         id: 3,
    //         value: 'OFI'
    //     },
    //     {
    //         id: 4,
    //         value: 'Observation'
    //     }
    // ],
      controlRecurringArray: [
        { label: "Daily", id: 1 },
        { label: "Weekly", id: 2 },
        { label: "Monthly", id: 3 },
        { label: "Quarterly", id: 4 },
        { label: "Half-Yearly", id: 5 },
        { label: "Yearly", id: 6 },
      ],
      dataLoading:true,
    };
  },
  components: {
    tableData,
    // loader,
    popup,
    breadCrumb,
    toolTip
    // Multiselect
  },
  async mounted() {
    this.dataLoading = true;
    await this.fetchDepartmentsList();
    await this.fetchAllKpisInfo();
    await this.getSingleComplianceFindings();
    await this.getPresentCompliance();
    this.dataLoading = false;
    // await this.startSelectedCompliance(this.$route.params.complianceId);
    // emitter.on("allFindings", (isData: any) => {
    //   let comingId = isData.entityData._id;
    //   let showObj = this.kpiList.find((obj: any) => {
    //     return obj._id === comingId;
    //   });
    //   this.showEditPopUp(showObj, isData.indexx);
    // });
    emitter.off('allFindings')
        emitter.on("allFindings", (isData: any) => {
          console.log("isData.entityData", isData.entityData)
          // let comingId = isData.entityData._id;
          // let showObj = this.kpiList.find((obj: any) => {
          //   return obj._id === comingId;
          // });
        this.showEditPopUp(isData.entityData);
        }); 
    await this.makeTable();
    emitter.off('activiteToolTip');
		emitter.on('activiteToolTip', (data: any) => {
			this.toolTipActive = true;
			this.toolTipText = data.tipText
		});
		emitter.off('deActiviteToolTip');
		emitter.on('deActiviteToolTip', (data: any) => {
			this.toolTipActive = false;
			this.toolTipText = ''
		});
  },
  // created(){
  //   this.routesArray = [
  //     { name: "Compliance", routeName: "createCompliance" },
  //     { name: "Findings", routeName: "complianceFindings" }
  //   ];  
  // },
  methods: {
    showEditPopUp(obj: any) {
      // this.showPopup = true;
      // this.allKpis = { ...obj };
      // this.hideFormula = true;
      this.$router.push({ name: 'findings', params: { findingId:  obj._id } });
      // if (this.tableRow[index].departments == 'N/A') {
      // 	this.naDepartments = this.tableRow[index].departments;
      // } else {
      // 	this.controlDepartmentArr = this.tableRow[index].departments;
      // }
      // if (Number.isInteger(obj.targetTimePeriod)) {
      // 	this.allKpis.targetTimePeriod = this.controlRecurringArray.find((itemOne: any) => {
      // 		return itemOne.id == obj.targetTimePeriod;
      // 	}).label;
      // }
      // if (Number.isInteger(obj.reportingFrequency)) {
      // 	this.allKpis.reportingFrequency = this.controlRecurringArray.find((itemOne: any) => {
      // 		return itemOne.id == obj.reportingFrequency;
      // 	}).label;
      // }
    },
    filterOpen() {
      if (this.columnObject == false) {
        this.showFilters = !this.showFilters;
        emitter.emit("visible", this.showFilters);
      }
    },
    async fetchAllKpisInfo() {
      try {
        await this.$http
          .get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/kpis/getall`)
          .then((result: { data: any }) => {
            this.isLoading = true;
            this.showLoader = true;
            this.allKpisList = result.data;
            _.each(
              this.allKpisList,
              (kpi: {
                kpis: any;
                controlNumber: any;
                controlTitle: any;
                departments: any;
              }) => {
                if (kpi.kpis) {
                  _.each(
                    kpi.kpis,
                    (kpi: {
                      controlNumber: any;
                      targetTimePeriod: any;
                      reportingFrequency: any;
                      controlTitle: any;
                      departments: any;
                    }) => {
                      // kpi.departments = kpi.departments;
                      this.kpiList.push(kpi);
                    }
                  );
                  this.dummyList = this.kpiList;
                }
              }
            );
          });
      } catch (e) {
        toast.error("error", {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }
      this.showLoader = false;
    },
    async getPresentCompliance(){
			await this.$http.get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/compliance/getAll`).then((result: any) => {
        this.presentCompliance = result.data
			})
		},
    async getSingleComplianceFindings(cId:any) {
        this.findingsForCompliance = [];
        await this.$http.get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/findings/getall`).then(async (result: any) => {
            this.findingsForCompliance = result.data;
        });
    },
    getLocationName(locationId: any) {
        let user = this.locationObject.find((obj: any) => {
            return obj.value == locationId;
        });
        if (user == undefined) {
            let empty = 'N/A';
            return empty;
        } else {
            return user.label;
        }
    },
    async fetchDepartmentsList() {
      (this.showLoader = true),
        //  (this.isLoading = false);
        await this.$http
          .get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`)
          .then((res: { data: any }) => {
            this.isLoading = true;
            this.showLoader = false;
            this.departmentsList = res.data;
          })
          .catch((error: any) => {
            this.isLoading = false;
            (this.showLoader = false),
              toast.error(`Save failed with ${error}`, {
                timeout: 1000,
                closeOnClick: true,
                closeButton: "button",
                icon: true,
              });
          });
    },
    // async startSelectedCompliance(cameId:any){
	// 		await this.$http.get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/compliance/${cameId}/startCompliance`).then((result: any) => {
    //     this.internalControlsDetailsArray = [...result.data];
	// 		})
	// 	},
    // makeTable() {
    //   let eachControlData: any
    //   this.internalControlsDetailsArray.forEach((controlData: any) => {
    //     eachControlData = controlData.controls
    //     // this.tableRow.push(finalKPIObj);
    //   });
    //   if(eachControlData.length != 0){
    //     eachControlData.forEach((kpi:any)=>{
    //       if(Object.keys(kpi).includes('kpis') && kpi.kpis.length !== 0){
    //         this.departmentsList.forEach((eachdepartment: any) => {
    //           if (kpi.departments == eachdepartment._id) {
    //             kpi.departments = eachdepartment.teamName;
    //           }
    //         });
    //         this.deptOptions = this.departmentsList.map((item: any) => {
    //           return { value: item._id, label: item.teamName };
    //         });
    //         let finalKPIObj
    //         kpi.kpis.map((eachKpi: any)=>{
    //           finalKPIObj = {
    //             _id: kpi._id,
    //             departments: kpi.departments ? kpi.departments : "N/A",
    //             number: kpi.controlNumber ? kpi.controlNumber : "N/A",
    //             "KPI Name": eachKpi.description,
    //             "weightage (%)": parseInt(eachKpi.weightage)
    //           };
    //         })
    //       this.tableRow.push(finalKPIObj);
    //       }
    //     })
    //   }
    // },
    makeTable() {
        this.deptOptions = this.departmentsList.map((item: any) => {
            return { value: item._id, label: item.teamName };
        });
        this.findingsForCompliance.forEach((obj: any) => {
          console.log("obj", obj)
          let complianceObj: any = this.presentCompliance.find((compliance:any)=>{
                return compliance._id == obj.complianceId
              })
            let finalObj = {
                _id: obj._id != undefined ? obj._id : 'N/A',
                'findingId': obj.findingId != undefined ? obj.findingId : 'N/A',
                'finding': obj.finding != undefined ? obj.finding : 'N/A',
                'compliance Title': complianceObj.title != undefined ? complianceObj.title : 'N/A',
                'createdAt' :this.extractDate(obj.createdAt) != undefined ? this.extractDate(obj.createdAt) : 'N/A',
                'statusCode' : obj.statusCode != undefined ? obj.statusCode : 'N/A',
            } 
            // let complianceObj: any = this.presentCompliance.find((compliance: any)=> compliance._id == obj.complianceId)
            // let complianceObj: any = this.presentCompliance.find((compliance:any)=>{
            //   return compliance._id == obj.complianceId
            // })
            // console.log('complianceObj',complianceObj)
            // obj.createdAt = this.extractDate(obj.createdAt)
            // console.log('obj',obj)
            this.tableRow.push(finalObj)
            // , 'compliance Title': complianceObj.title != undefined ? complianceObj.title : 'N/A'
        });
        for (const row of this.tableRow) {
            for (const value of Object.values(row)) {
            if (value == "") {
                this.columnObject = true;
            } else {
                this.columnObject = false;
            }
            }
        }
    },
    extractDate(datetime : any) {
      return datetime.split(' ')[0];
    },
  },
});
</script>

