<template>
	<div class="px-5 pt-3 mb-5">
		<div class="items-center justify-between flex">
			<div class="title flex items-center">
				<h1>Compliance</h1>
			</div>
			<div class="flex items-center">
                <button class="btnprimary ml-2 flex items-center" @click="addBtnCompliance()">Add</button>
				<button class="ml-3 flex" @click="filterOpen" :class="tableRow.length == 0 ? 'filterButtondisabled pointer-events-none' : 'filterButton'">
					Filters
					<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
				</button>
			</div>
		</div>
		<div class="mt-3">
			<p class="headerText">
				This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
				to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
			</p>
		</div>
		<tableData :tableRowData="tableRow" :headersData="headers" :tableRouteArr="optionsValue" :loadData="dataLoading"></tableData>
		<div v-if="toolTipActive">
			<tool-tip :tipText="toolTipText"></tool-tip>
		</div>
	</div>
</template>
<style scoped>
</style>
<script lang="ts">
import { emitter, toast } from '@/main';
import { defineComponent } from 'vue';
// import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import tableData from '@/components/tableData.vue';
// import popup from '@/components/popup.vue';
// import Multiselect from '@vueform/multiselect';
import Multiselect from 'vue-multiselect';
// import loader from '@/components/loader.vue';
import _ from 'lodash';
import toolTip from '@/components/toolTip.vue'

export default defineComponent({
	data(): any {
		return {
			headers:['complianceId','title','compliancePeriod','startDate','endDate','Reviewer','complianceStatus','actionButtonAtCompliance'],
            // v$: useVuelidate(),
            complianceList: [],
			dummyList: [],
			optionUserArray:[],
			tableRow: [],
			showFilters: false,
			actionBtn:true,
			optionsValue: [
                {
                    id: 1,
                    value: 'Edit',
                },
                {
                    id: 2,
                    value: 'View',
                },
                // {
                //     id: 3,
                //     value: 'Findings',
                // },
                {
                    id: 5,
                    value: 'Controls',
                },
                {
                    id: 6,
                    value: 'Reports',
                },
              
            ],
			toolTipActive:false,
			toolTipText:'',
			dataLoading:true,
			
		};
	},
	components: {
		tableData,
	    toolTip
	},
	mounted(){
		emitter.off('allComplianceView')
		emitter.on('allComplianceView', (isData: any) =>{
			console.log("isDta", isData)
			let data = isData.entityData
			this.viewComplianceAction(data);
		});
		emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {                        
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
	},
	methods: {
		filterOpen() {
			this.showFilters = !this.showFilters;
			if (this.showFilters == true) {
                this.dummyList = this.complianceList;
            } else {
                this.complianceList = this.dummyList;
            }
			emitter.emit('visible', this.showFilters);
		},
		changeDateFormat(date: any) {
			if (date.includes('-')) {
				let dateParts = date.split('-');
				if (dateParts.length === 6) {
				let startDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
				let endDate = `${dateParts[5]}/${dateParts[4]}/${dateParts[3]}`;
				return `${startDate} - ${endDate}`;
				} else if (dateParts.length === 3) {
				return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
				}
			}
			return date.split('-').reverse().join('/');
		},
        addBtnCompliance(){
            this.$router.push({name:'createCompliance'})
        },
		viewComplianceAction(data:any){
			this.$router.push({name:'viewCompliance', params: { complianceId: data._id } })
		},
		async getAllUsers() {
			this.optionUserArray = []
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				for (var i = 0; i < res.data.length; i++) {
					this.optionUserArray.push({ label: res.data[i].name, value: res.data[i]._id });
					
				}
				console.log("optionUserArray",this.optionUserArray)
			});
		},
		async fetchComplianceList() {
			this.complianceList = [];
			this.tableRow = [];
				await this.$http.get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/compliance/getAll`).then((result:any) => {
					console.log('COM',result.data)
					result.data.forEach((info: any) => {
					this.complianceList.push(info);
					this.dummyList.push(info);
				});
				this.complianceList.forEach((complianceObj: any) => {
				let findName = this.optionUserArray.find((obj:any)=>{return obj.value == complianceObj.teamLeader});
			    let compliancePeriodFormat = `${this.changeDateFormat(complianceObj.period.fromDate)} - ${this.changeDateFormat(complianceObj.period.toDate)}`
				let compilanceStartDate = `${this.changeDateFormat(complianceObj.startDate)}`
				let compilanceEndDate = `${this.changeDateFormat(complianceObj.endDate)}`
				let finalObj = {
					_id: complianceObj._id,
					complianceId: complianceObj.complianceId != undefined ? complianceObj.complianceId : 'N/A',
					title: complianceObj.title != undefined ? complianceObj.title : 'N/A',
					startDate: compilanceStartDate != undefined ? compilanceStartDate : 'N/A',
					endDate : compilanceEndDate != undefined ? compilanceEndDate : 'N/A',
					compliancePeriod: compliancePeriodFormat != undefined ? compliancePeriodFormat : 'N/A',
					Reviewer:findName!=undefined? findName.label:'N/A',
					complianceStatus: complianceObj.statusCode != undefined ? complianceObj.statusCode : 'N/A',
					createdBy: complianceObj.createdBy != undefined ? complianceObj.createdBy : 'N/A'
				}
				this.tableRow.push(finalObj);
				console.log('TAb',this.tableRow)
			    });
                })
			} 
	},
	async created() {
		this.dataLoading = true;
		await this.getAllUsers();
		await this.fetchComplianceList();
		this.dataLoading = false;
		
	}
});
</script>

