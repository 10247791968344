<template>
	<div class="Content p-4">
		<div class="mainContainer">
			<h1 class="title">Controls</h1>
			<div class="mt-4">
				<div v-if="dummyList.length != 0" class="datacontainer">
				<div class="btnposition flex flex-row-reverse">
						<button class="filterButton flex" @click="visibility = !visibility" v-if="sortedData.length > 0">
							Filters
							<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
						</button>
					<button class="btnprimary mr-2" v-if="showAdditionalFilterArea" @click="generateTasks()">Generate</button>
				</div>
				<div class="tableContainer">
					<table v-if="dummyList.length != 0">
						<thead>
							<tr>
								<th class="">
									<input type="checkbox" name="checkbox" disabled />
								</th>

								<th class="header cursor-pointer" @click="sort('controlNumber')">
									<div class="flex items-center">
										<div>#</div>
										<div class="sort cursor-pointer ml-4" :class="className == 'controlNumber' ? classSort : ''"></div>
									</div>
								</th>
								<th class="header cursor-pointer" @click="sort('controlTitle')">
									<div class="flex items-center">
										<div>Title</div>
										<div class="sort cursor-pointer ml-4" :class="className == 'controlTitle' ? classSort : ''"></div>
									</div>
								</th>
								<th class="header cursor-pointer" @click="sort('controlPriority')">
									<div class="flex items-center">
										<div>Control Priority</div>
										<div class="sort cursor-pointer ml-4" :class="className == 'controlPriority' ? classSort : ''" @click="sort('controlPriority')"></div>
									</div>
								</th>
								<th class="header cursor-pointer" @click="sort('controlClass')">
									<div class="flex items-center">
										<div>Class</div>
										<div class="sort cursor-pointer ml-4" :class="className == 'controlClass' ? classSort : ''" @click="sort('controlClass')"></div>
									</div>
								</th>
								<th class="header cursor-pointer" @click="sort('controlFamily')">
									<div class="flex items-center">
										<div>Family</div>
										<div class="sort cursor-pointer ml-4" :class="className == 'controlFamily' ? classSort : ''" @click="sort('controlFamily')"></div>
									</div>
								</th>

								<th>KPI</th>
							</tr>
							<tr v-if="visibility">
								<td></td>
								<td class="searchRow">
									<input type="text" class="mySearch" v-on:keyup="filteredFunction($event.target.value, 'controlNumber')" />
								</td>
								<td class="searchRow">
									<input type="text" class="mySearch" v-on:keyup="filteredFunction($event.target.value, 'controlTitle')" />
								</td>
								<td class="searchRow">
									<input type="text" class="mySearch" v-on:keyup="filteredFunction($event.target.value, 'controlPriority')" />
								</td>
								<td class="searchRow">
									<input type="text" class="mySearch" v-on:keyup="filteredFunction($event.target.value, 'controlClass')" />
								</td>
								<td style="searchRow">
									<input type="text" class="mySearch" v-on:keyup="filteredFunction($event.target.value, 'controlFamily')" />
								</td>
								<td></td>
							</tr>
						</thead>
						<tbody v-if="sortedData.length > 0">
							<tr v-for="(rowData, rindex) in sortedData" :key="rindex">
								<td>
									<input type="checkbox" name="checkbox" v-bind:value="rowData" v-model="selectedObjects" :disabled="rowData.kpis ? false : true" @change="updateCheckall()" />
								</td>
								<td>{{ rowData.controlNumber }}</td>
								<td>{{ rowData.controlTitle }}</td>
								<td>{{ rowData.controlPriority }}</td>
								<td>{{ rowData.controlClass }}</td>
								<td>{{ rowData.controlFamily }}</td>
								<td>{{ rowData.kpis ? rowData.kpis.length : '0' }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			</div>

			<div v-if="sortedData.length == 0 && dummyList.length == 0" class="text-center">
				<div class="mainErrorBlock">
					<div>
						<img src="../../assets/noData.svg" alt="sssss" class="imgSizing" />
						<h5 style="color: #c4c4c4">You dont have any control</h5>
					</div>
				</div>
			</div>
			<div class="buttonContainer  flex justify-between mt-2" v-if="tableBody.length > pageSize">
				<div class="total text-black">Total {{ tableBody.length }}</div>
				<div class="pagecontainer flex justify-center cursor-pointer">
					<div class="pagination">
					<img src="@/assets/leftArrowFilled.svg"  class="w-5 h-5 mt-0.5 " :class="cantGoBack ? 'disabled' : ''" @click="prevPage" />
					</div>
					<div class="text-black mx-2">
						{{ (this.currentPage - 1) * this.pageSize + 1 }} -
						{{ this.currentPage * this.pageSize }}
					</div>
					<div class="pagination">
						<img src="@/assets/rightArrowFilled.svg" class="w-5 h-5 mt-0.5" :class="cantGoForward ? 'disabled' : ''" @click="nextPage" />
					</div>
					
				</div>
			</div>
		</div>
		<!-- <loader v-if="showLoader" /> -->
	</div>
</template>
<style scoped>
th:last-child,
td:last-child {
	width: 85px;
}
th:first-child,
td:first-child {
	width: 75px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash'
// import loader from '../../components/loader.vue';
import { toast } from '@/main';
export default defineComponent({
	data():any {
		return {
			dummyList: [],
			isLoading: false,
			showLoader: false,
			visibility: false,
			tableHeaders: [],
			tableBody: [],
			isCheckAll: false,
			selectedObjects: [],
			currentSort: 'name',
			currentSortDir: 'asc',
			classSort: '',
			className: '',
			pageSize: 12,
			currentPage: 1,
			showAdditionalFilterArea: false,
			departmentId: 0,
			value: ''
		};
	},
	// components: {
	// 	loader
	// },
	computed: {
		sortedData: function ():any {
			return [...this.tableBody]
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		cantGoBack() :any{
			return this.currentPage === 1;
		},
		cantGoForward():any {
			return this.tableBody.length / this.pageSize <= this.currentPage;
		}

		// }
	},
	created() {
		this.fetchControlsInfo();
	},
	methods: {
		filteredFunction(filterColumn: string, columnName: string) {
			this.tableBody = this.dummyList.filter((item: { [x: string]: string; }) => {
				for (var property in item) {
					if (property == columnName) {
						return item[property].toLowerCase().includes(filterColumn.toLowerCase());
					}
				}
			});
		},

		fetchControlsInfo() {
			(this.showLoader = true), (this.isLoading = false);
			this.$http
				.get(`${process.env.VUE_APP_CONTROLS_API_URL}/controls/api/org/allControls`)
				.then((result: any) => {
					this.isLoading = true;
					(this.showLoader = false),
						_.each(result.data.map(_.keys)[0], (title: string) => {
							if (title !== 'controlObjective') {
								var temp:any = {};
								temp.title = title;
								if (title == 'controlName') {
									temp.sortRequired = true;
									temp.sortKey = title;
								}
								this.tableHeaders.push(temp);
							}
						});

					this.tableBody = result.data;

					let x: any[] = [];
					_.each(result.data, (control: { kpis: undefined; }) => {
						if (control.kpis != undefined) {
							x.push(_.pick(control, ['controlNumber', 'controlName', 'controlPriority', 'controlClass', 'controlFamily', 'controlTitle', 'kpis', 'departments', '_id']));
						}
					});
					this.tableBody = x;
					this.dummyList = x;
				})
				.catch((error: any) => {
					this.isLoading = false;
					(this.showLoader = false),
						toast.error(`Save failed with ${error}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		checkAll: function () {
			this.isCheckAll = !this.isCheckAll;
			this.selectedObjects = [];
			if (this.isCheckAll) {
				for (var key in this.tableBody) {
					if (this.tableBody[key].kpis) this.selectedObjects.push(this.tableBody[key]);
				}
			}
		},
		updateCheckall: function () {
			if (this.selectedObjects.length == this.tableBody.length) {
				this.isCheckAll = true;
			} else {
				this.isCheckAll = false;
			}
			this.showAdditionalFilterArea = this.selectedObjects.length > 0;
		},
		sort: function (s: any) {
			this.className = s;
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = s;
			this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
		},
		nextPage: function () {
			if (this.currentPage * this.pageSize < this.tableBody.length) this.currentPage++;
		},
		prevPage: function () {
			if (this.currentPage > 1) this.currentPage--;
		},
		generateTasks() {
			let kpis: any[] = [];
			_.each(this.selectedObjects, (control: { kpis: any; departments: string|any[]; _id: any; }) => {
				_.each(control.kpis, (kpi: { taskStatus: string; mappedControlID: any; departmentId: any; _id: any; }) => {
					if (control.departments.length > 0) {
						_.each(control.departments, (dept: any) => {
							kpi.taskStatus = 'OPEN';
							kpi.mappedControlID = control._id;
							kpi.departmentId = dept;
							kpis.push(kpi);
							delete kpi._id;
						});
					} else {
						kpi.taskStatus = 'OPEN';
						kpi.mappedControlID = control._id;
						kpi.departmentId = control.departments[0];
						kpis.push(kpi);
						delete kpi._id;
					}
				});
			});
			this.$http.post(`${process.env.VUE_APP_MONITOR_API_URL}}/api/org/taskmaster/create`, kpis).then(() => {
				toast.success(`Generated Successfully`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
				this.$router.push({ name: 'tasks' });
			});
		}
	}
});
</script>