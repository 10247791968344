<template>
	<div class="pr-5 pl-5 pt-3">
		<div class="row w-full mb-3 justify-between flex" style="align-items: center">
			<h1>Mapping</h1>
			<div class="cardbody">
				<div class="flex flex-row-reverse items-center">
					<div class="flex flex-row-reverse items-center">
						<button class="flex" @click="filterOpen" :class="tableRow.length == 0 ? 'filterButtondisabled pointer-events-none' : 'filterButton'">
							Filters
							<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
						</button>
					</div>
				</div>
			</div>
		</div>
		<div>
			<tableData :tableRowData="tableRow" :headersData="headers" :tableRouteArr="optionsValue" :loadData="dataLoading"></tableData>
			<div v-if="toolTipActive">
				<tool-tip :tipText="toolTipText"></tool-tip>
			</div>
		</div>
		<popup v-if="showActivityPopup" :popUp="showActivityPopup">
				<template v-slot:header>
					<div class="flex items-center justify-between py-4 px-2 rounded">
						<h1 class="pl-2">Activity</h1>
						<button class="float-right text-3xl" @click="onCancelbtn()">
							<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
						</button>
					</div>
				</template>
				<fieldset>
					<div>
						<div>
							<div class="statuscontainer popupbodycontainer">
								<div class="formcontainer flex flex-col">
									<div class="row flex flex-row w-full">
										<div class="row flex flex-row w-full">
											<div class="w-6/12">
												<label class="text-xs font-extrabold">Activity</label>
												<div class="mb-3 mt-2 text-sm">{{ activityObj?.activityName || 'N/A'}}</div>
											</div>
											<div class="w-3/6 leftspacing">
												<label class="text-xs font-extrabold">Team</label>
												<div class="mb-3 mt-2 text-sm">{{ activityObj?.teamName || 'N/A'}}</div>
											</div>
										</div>
									</div>
									<div class="row flex flex-row w-full">
										<div class="w-6/12">
											<label class="text-xs font-extrabold">Description</label>
											<div class="mb-3 mt-2 text-sm">{{ activityObj?.description || 'N/A' }}</div>		
										</div>
									</div>
									<!-- criticality -->
									<div class="row flex flex-row w-full">
										<div class="w-6/12">
											<label class="text-xs font-extrabold">Activity Owner</label>
											<div class="mb-3 mt-2 text-sm">{{ activityObj?.activityOwner || 'N/A'}}</div>
										</div>
										<div class="w-3/6 leftspacing">
											<label class="text-xs font-extrabold">Entity</label>
											<div class="mb-3 mt-2 text-sm">{{ activityObj?.displayName || 'N/A' }}</div>
										</div>
									</div>
									<div class="row flex flex-row w-full">
										<div class="w-3/6">
											<label class="text-xs font-extrabold">Criticality</label>
											<div class="mb-3 mt-2 text-sm">{{ activityObj?.criticality || 'N/A' }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</popup>
		<!-- <loader v-if="showLoader" /> -->
	</div>
</template>
<style scoped>
</style>
<script lang="ts">
import { emitter, toast } from '@/main';
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import tableData from '@/components/tableData.vue';
import popup from '@/components/popup.vue';
import toolTip from '@/components/toolTip.vue';
// import loader from '@/components/loader.vue';
export default defineComponent({
	data(): any {
		return {
			headers:['_id','Activities', 'Activity Owner','Team', 'Entity', 'criticality', 'Total Number of Controls'],

			v$: useVuelidate(),
			departmentsList: [],
			tableRow: [],
			activityObj: {},
			orgUsersList: [],
			optionsUserArray: [],
			controlsCountList: [],
			columnObject: false,
			showActivityPopup: false,
			showLoader: false,
			showFilters: false,
			criticalityArray: [
				{ label: 'Very Low', id: 1 },
				{ label: 'Low', id: 2 },
				{ label: 'Moderate', id: 3 },
				{ label: 'High', id: 4 },
				{ label: 'Critical', id: 5 }
			],
			toolTipActive : false,
			toolTipText : '',
			optionsValue: [
				{
					id: 1,
					value: 'Select',
					route: '/departmentControls',
					presentRoute: '/org/controls/controlMapping'
				}
			], 
			dataLoading:true,
		};
	},
	components: {
		tableData,
		// loader,
		popup,
		toolTip
	},
	mounted() {
		for (const row of this.tableRow) {
			for (const value of Object.values(row)) {
				if (value == '') {
					this.columnObject = true;
				} else {
					this.columnObject = false;
				}
			}
		}
		emitter.off('controlMappingData')
        emitter.on("controlMappingData", (isData: any) => {
			let comingId = isData.entityData._id;
			let showObj = this.departmentsList.find((obj: any) => obj._id === comingId);
			this.showEditPopUp(showObj);
        });
		emitter.off('activiteToolTip');
		emitter.on('activiteToolTip', (data: any) => {
			this.toolTipActive = true;
			this.toolTipText = data.tipText
		});
		emitter.off('deActiviteToolTip');
		emitter.on('deActiviteToolTip', (data: any) => {
			this.toolTipActive = false;
			this.toolTipText = ''
		});
	},
	methods: {
		showEditPopUp(obj: any) {
			console.log("obj", obj)
        //    this.$router.push({ name: 'departmentControls', params: { id:  obj._id } });
			this.showActivityPopup = true;
			let a = this.criticalityArray.find((value: any)=>{return obj.criticality== value.id}).label;
			let owner = this.optionsUserArray.find((user: any) => user.value === obj.activityOwner)?.label || 'N/A';
			this.activityObj = {...obj}
			this.activityObj.activityOwner = owner
			this.activityObj.criticality = a
		},
		filterOpen() {
			if(this.columnObject == false){
				this.showFilters = !this.showFilters;
				emitter.emit('visible', this.showFilters);
			}
		},
		onCancelbtn() {
			this.v$.$reset();
			this.showActivityPopup = false;
			this.activityObj = {}
		},
		async getAllUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.orgUsersList = res.data;
			});
			for (var i = 0; i < this.orgUsersList.length; i++) {
				this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
			}
		},
		async fetchControlsCountInfo() {
			this.controlsCountList = [];
			try {
				this.showLoader = true;
				await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/departments/mappedcontrols/count`).then((result: { data: any }) => {
					this.controlsCountList = result.data;
				});
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			this.showLoader = false;
		},
		async getSingleEntity(){
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
				this.organizationList = res.data;
			});
			// this.$store.dispatch('updateDotsLoading', false);
		},
		async fetchDepartmentsList() {
			try {
				this.showLoader = true;
				this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: { data: any }) => {
					// this.departmentsList = res.data;
					// console.log("departmentsList", this.departmentsList)
				});
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			this.showLoader = false;
		},
		async fetchActivitiesList() {
			try {
				this.showLoader = true;
				this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/processes/mappedcontrols/count`).then((res: { data: any }) => {
					this.departmentsList = res.data;
					console.log("departmentsList", this.departmentsList)
					if (this.departmentsList.length > 0) {
						this.departmentsList.forEach((department: any) => {
                            let finalObj = {
                                _id: department._id,
								'Activities': department.activityName ? department.activityName: 'N/A',
                                'Team': department.teamName ? department.teamName : 'N/A',
                                'Entity': department.displayName ? department.displayName : 'N/A',
                                'Activity Owner': this.optionsUserArray.find((user: any) => user.value === department.activityOwner)?.label || 'N/A',
                                'Total Number of Controls': department.controlCount && department.controlCount ? department.controlCount : '0',
								'criticality': department.criticality ? department.criticality: 'N/A',
                                'entityId': department.entityDisplayName,
                                'type': department.entityType
                            };
							console.log("finalObj", finalObj)
                            this.tableRow.push(finalObj);
							this.dataLoading = false;
                        });
					} else {
						let finalObj = {
							_id: '',
							'Activities': '',
							'Team': '',
							'Entity': '',
							'Activity Owner': '',
							'criticality': '',
							'Total Number of Controls': ''
						};
						this.tableRow.push(finalObj);
						this.dataLoading = false;
					}
				});
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			this.showLoader = false;
		}
	},
	async created() {
		this.dataLoading = true;
		await this.fetchControlsCountInfo();
		await this.getAllUsers();
		await this.getSingleEntity();
		await this.fetchActivitiesList();
		// await this.fetchDepartmentsList();
	}
});
</script>

