
import { createRouter, createWebHistory, NavigationGuardNext, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { controlsNavigationGuards, kpisNavigationGuards,tasksGenerateNavigationGuards,dashboardNavigationGuard,admiNavigationGuard } from './navGuard'
// import configure from '../pages/org-pages/configure.vue';
import activeControls from '../pages/org-pages/active-controls.vue'
import defaultLayout from '../pages/defaultLayout.vue';
import adminLayout from '../pages/adminLayout.vue'
import viewEdit from '../pages/org-pages/viewEdit.vue';
import importKPIs from '../pages/root/importKPIs.vue';
import controlMapping from '../pages/org-pages/edit-control.vue';
import objectControlMaping from '../pages/org-pages/objectiveControlsMap.vue';
import objective from '../pages/org-pages/objective.vue';
import frameworks from '../pages/org-pages/frameworks.vue';
import dataEntry from '../pages/org-pages/data-entry.vue';
import internalControl from '../pages/org-pages/departments.vue';
import viewKpis from '../pages/org-pages/viewKpi.vue';
import manageKpis from '../pages/org-pages/manageKPI.vue';
// import manageOrgControlMap from '../pages/org-pages/manageOrgControlMap.vue';
import manageRootControlMap from '../pages/root/manageRootControlMap.vue';
// import kpiControlMap from '../pages/org-pages/kpiControlMap.vue';
// import kpiRootControlMap from '../pages/root/kpiRootControlMap.vue';
import tasks from '../pages/org-pages/tasks.vue';
import dashboard from '../pages/org-pages/dashBoard.vue';
import manage from '../pages/root/home.vue';
import rootControls  from '../pages/root/rootControls.vue';
import rootFrameworks  from '../pages/root/rootFrameworks.vue';
import createConfiguration from '../pages/root/create-control.vue';
import error from '../pages/org-pages/error.vue';
import errorPage from '../pages/org-pages/errorPage.vue';
// import createCustom from '../pages/org-pages/controlv1.vue';
import complianceTasks from '../pages/org-pages/taskEdit.vue';
import tenantControls from '../pages/org-pages/tenantControls.vue';
import tenantFrameWorks from '../pages/root/tenantFrameWorks.vue';
import createCompliance from '../pages/org-pages/compliance.vue';
import editCompliance from '../pages/org-pages/compliance.vue';
import compliance from '../pages/org-pages/createCompliance.vue';
import startCompliance from '../pages/org-pages/startCompliance.vue';
import masterKPI from '../pages/org-pages/masterKPI.vue';
import viewCompliance from '../pages/org-pages/viewCompliance.vue'
import complianceControls from '../pages/org-pages/complianceControls.vue'
import complianceFindings from '../pages/org-pages/complianceFindings.vue'
import findings from '../pages/org-pages/findings.vue'
import findingTasks from '../pages/org-pages/findingTasks.vue'
import actionTask from '../pages/org-pages/actionTaskEdit.vue'
import controlsImport from '../pages/org-pages/importControls.vue'
import systemLevel from '../pages/org-pages/systemLevel.vue'
import systemControlMapping from '../pages/org-pages/systemControlMapping.vue'
const controlRoutes:Array<RouteRecordRaw> = [
    // {
    //     path: '/org/controls/configure',
    //     name: 'Configuration',
    //     meta: {
    //         title: 'Configuration',
    //         layout: defaultLayout,
    //     },
    //     component:configure,
    //     // beforeEnter: (to, from, next) => {
    //     //     console.log('guard triggered');
    //     //     controlsNavigationGuards(to, from, next)
    //     // }
    // },
    {
        path: '/org/createCompliance',
        name: 'createCompliance',
        meta: {
            title: 'createCompliance',
            layout: defaultLayout,
        },
        component:createCompliance,
        // beforeEnter: (to, from, next) => {
        //     console.log('guard triggered');
        //     controlsNavigationGuards(to, from, next)
        // }
        
    },
    {
        path: '/org/compliance/:complianceId/viewCompliance',
        name: 'viewCompliance',
        meta: {
            title: 'viewCompliance',
            layout: defaultLayout,
        },
        component:viewCompliance,


    },
    {
        path: '/org/compliance/:complianceId/complianceControls',
        name: 'complianceControls',
        meta: {
            title: 'complianceControls',
            layout: defaultLayout,
        },
        component:complianceControls,


    },
    // {
    //     path: '/org/compliance/:complianceId/complianceFindings',
    //     name: 'complianceFindings',
    //     meta: {
    //         title: 'complianceFindings',
    //         layout: defaultLayout,
    //     },
    //     component:complianceFindings,


    // },
    {
        path: '/org/controlsImport',
        name: 'controlsImport',
        meta: {
            title: 'controlsImport',
            layout: defaultLayout,
        },
        component:controlsImport,


    },
    {
        path: '/org/findings',
        name: 'complianceFindings',
        meta: {
            title: 'complianceFindings',
            layout: defaultLayout,
        },
        component:complianceFindings,


    },
    {
        path: "/finding/:findingId/findingInfo",
        name: "findings",
        meta: {
            title: "Findings",
            layout: defaultLayout,
        },
        component: findings,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
        // beforeEnter: (to, from, next) => {
            // adminNavigationGuard(to, from, next)
        //   },
    },
    {
        path: "/task/findingTasks",
        name: "findingTasks",
        meta: {
            title: "Finding-Tasks",
            layout: defaultLayout,
        },
        component: findingTasks,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
        // beforeEnter: (to, from, next) => {
            // adminNavigationGuard(to, from, next)
        //   },
    },
    {
        path: "/finding/:findingId/task/:taskId/actionTask",
        name: "actionTask",
        meta: {
            title: "Action-Task",
            layout: defaultLayout,
        },
        component: actionTask,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
        // beforeEnter: (to, from, next) => {
            // adminNavigationGuard(to, from, next)
        //   },
    },
    {
        path: '/org/compliance',
        name: 'compliance',
        meta: {
            title: 'Compliance',
            layout: defaultLayout,
        },
        component:compliance,
        // beforeEnter: (to, from, next) => {
        //     console.log('guard triggered');
        //     controlsNavigationGuards(to, from, next)
        // }
        
    },
    {
        path: "/org/Compliance/:complianceId/startCompliance",
        name: "start-Compliance",
        meta: {
            title: "Start-Compliance",
            layout: defaultLayout,
        },
        component: startCompliance,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
       
    },
    {
        path: "/org/Compliance/:complianceId/editCompliance",
        name: "editCompliance",
        meta: {
            title: "Edit-Compliance",
            layout: defaultLayout,
        },
        component: editCompliance,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
       
    },
    {
        path: '/org/controls/active',
        name: 'Active-Controls',
        meta: {
            title: 'Active Controls',
            layout: defaultLayout,
        },
        component: activeControls,
    },
    {
        path: '/org/controls/viewConfiguration',
        name: 'View-Configuration',
        meta: {
            title: 'View Configuration',
            layout: defaultLayout,
        },
        component: viewEdit,
        // beforeEnter: (to, from, next) => {
        //     controlsNavigationGuards(to, from, next)
        // }
    },
    
    {
        path: "/compliance/:complianceId/task/:taskId/complianceTasks",
        name: "Compliance-Tasks",
        meta: {
            title: "Task-Edit",
            layout: defaultLayout,
        },
        component: complianceTasks,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
        // beforeEnter: (to, from, next) => {
            // adminNavigationGuard(to, from, next)
        //   },
    },
    {
        path:'/org/masterKPI',
        name:'masterKPI',
        meta:{
            title:'masterKPI',
            layout:defaultLayout
        },
        component:masterKPI
    },
    {
        path: '/org/department/:id/controls',
        name: 'departmentControls',
        meta: {
          title: 'Map',
          layout: defaultLayout,
        },
        component: controlMapping,
        // beforeEnter: (to, from, next) => {
        //     controlsNavigationGuards(to, from, next)
        // }
    },
    
    // {
    //     path: '/org/controls/configure/createx/:type',
    //     name: 'Create-Configuration1',
    //     meta: {
    //         title: 'create Configuration',
    //     },
    //     component: () => import('@/pages/org-pages/create-control.vue'),
    //     beforeEnter: (to, from, next) => {
    //         controlsNavigationGuards(to, from, next)
    //     }
    // },
    // {
    //     path: '/org/controls/configure/create/:type/:id?/:setname?',
    //     name: 'Create2',
    //     meta: {
    //         title: 'create Configuration',
    //         layout: defaultLayout,
    //     },
    //     component:  createCustom,
    //     // beforeEnter: (to, from, next) => {
    //     //     controlsNavigationGuards(to, from, next)
    //     // }
    // },
  
    {
        path: '/org/controls/data',
        name: 'Data-Entry',
        meta: {
            title: 'Data Entry',
            layout: defaultLayout,
        },
        component:dataEntry,
        // beforeEnter: (to, from, next) => {
        //     controlsNavigationGuards(to, from, next)
        // }
    },
    {
        path: '/org/controls/controlMapping',
        name: 'controlMapping',
        meta: {
            title: 'Mapping',
            layout: defaultLayout,
        },
        component:internalControl,
        // beforeEnter: (to, from, next) => {
        //     controlsNavigationGuards(to, from, next)
        // }
    },
    {
        path: '/org/objective',
        name: 'objective',
        meta: {
          title: 'Objective',
          layout: defaultLayout,
        },
        component: objective,
        // beforeEnter: (to, from, next) => {
        //     controlsNavigationGuards(to, from, next)
        // }
    },
    {
        path: '/org/objective/:objectiveId/controls',
        name: 'objectiveControls',
        meta: {
          title: 'Map',
          layout: defaultLayout,
        },
        component: objectControlMaping,
        // beforeEnter: (to, from, next) => {
        //     controlsNavigationGuards(to, from, next)
        // }
    },
    {
        path: '/org/frameworks',
        name: 'frameworks',
        meta: {
          title: 'Frameworks',
          layout: defaultLayout,
        },
        component: frameworks,
        // beforeEnter: (to, from, next) => {
        //     controlsNavigationGuards(to, from, next)
        // }
    },
    // {
    //     path: '/org/framework/:frameworkId/kpiControlMap',
    //     name: 'kpi Control Mapping',
    //     meta: {
    //       title: 'kpiControlMap',
    //       layout: defaultLayout,
    //     },
    //     component: kpiControlMap,
    //     // beforeEnter: (to, from, next) => {
    //     //     controlsNavigationGuards(to, from, next)
    //     // }
    // },
    {
        path: '/org/:tenantId/manageControlframeWorks',
        name: 'tenantControlsOrg',
        meta: {
            title: 'tenant-controls',
            layout: defaultLayout,
        },
        component: tenantControls,
        // beforeEnter: (to, from, next) => {
        //     admiNavigationGuard(to, from, next)
        // }
    },
]
const kpiRoutes:Array<RouteRecordRaw> = [
    {
        path: '/org/viewKPIS',
        name: 'View-KPIS',
        meta: {
            title: 'View KPIS',
            layout: defaultLayout,
        },
        component: viewKpis,
        // beforeEnter: (to, from, next) => {
        //     kpisNavigationGuards(to, from, next)
        // }
    },
    {
        path: '/org/control/:id/manageKPI',
        name: 'manageKPI',
        meta: {
            title: 'KPIS',
            layout: defaultLayout,
        },
        component: manageKpis,
        // beforeEnter: (to, from, next) => {
        //     kpisNavigationGuards(to, from, next)
        // }
    },
    {
        path: '/org/systems',
        name: 'systemLevel',
        meta: {
            title: 'systemLevel',
            layout: defaultLayout,
        },
        component: systemLevel,
        // beforeEnter: (to, from, next) => {
        //     kpisNavigationGuards(to, from, next)
        // }
    },
    {
        path: '/org/systems/:systemId/systemControlMapping',
        name: 'systemControlMapping',
        meta: {
            title: 'systemControlMapping',
            layout: defaultLayout,
        },
        component: systemControlMapping,
        // beforeEnter: (to, from, next) => {
        //     kpisNavigationGuards(to, from, next)
        // }
    },
    // {
    //     path: '/org/framework/:id/manageOrgControlMap',
    //     name: 'Manage Control Mapping',
    //     meta: {
    //         title: 'KPIS',
    //         layout: defaultLayout,
    //     },
    //     component: manageOrgControlMap,
    //     // beforeEnter: (to, from, next) => {
    //     //     kpisNavigationGuards(to, from, next)
    //     // }
    // },
    
]
const tasksRoutes = [
    {
        path: '/org/tasks',
        name: 'tasks',
        meta: {
            title: 'tasks',
            layout: defaultLayout,
        },
        component: tasks
    },
]
const dahsboardRoutes :Array<RouteRecordRaw>= [
    {
        path: '/org/dashboard',
        name: 'dashboard',
        meta: {
            title: 'dashBoard',
            layout: defaultLayout
        },
        component:dashboard,
        // beforeEnter: (to, from, next) => {
        //     dashboardNavigationGuard(to, from, next)
        // }
    }
]
 const adminRoutes :Array<RouteRecordRaw>= [
    {
        path: '/org/root/configure',
        name: 'manage',
        meta: {
            title: 'Manage',
            layout: adminLayout,
        },
        component:manage,
        // beforeEnter: (to, from, next) => {
        //     admiNavigationGuard(to, from, next)
        // }
    },
    {
        path: '/root/allControls',
        name: 'root-controls',
        meta: {
            title: 'root-controls',
            layout: adminLayout,
        },
        component: rootControls,
        // beforeEnter: (to, from, next) => {
        //     admiNavigationGuard(to, from, next)
        // }
    },
    {
        path: '/root/allKpis',
        name: 'root-kpis',
        meta: {
            title: 'Root-KPIs',
            layout: adminLayout,
        },
        component: importKPIs,
        // beforeEnter: (to, from, next) => {
        //     admiNavigationGuard(to, from, next)
        // }
    },
    {
        path: '/org/root/configure/create/:type/:id?',
        name: 'Create-Configuration',
        meta: {
            title: 'create Configuration',
            layout: adminLayout,
        },
        component: () => createConfiguration,
        // beforeEnter: (to, from, next) => {
        //     admiNavigationGuard(to, from, next)
        // }
    },
    {
        path: '/root-Frameworks',
        name: 'root-Frameworks',
        meta: {
            title: 'root-Frameworks',
            layout: adminLayout,
        },
        component: rootFrameworks,
        // beforeEnter: (to, from, next) => {
        //     admiNavigationGuard(to, from, next)
        // }
    },
    {
        path: '/root/tenant/frameWorks',
        name: 'tenantFrameWorks',
        meta: {
            title: 'tenant-frameWorks',
            layout: adminLayout,
        },
        component: tenantFrameWorks,
        // beforeEnter: (to, from, next) => {
        //     admiNavigationGuard(to, from, next)
        // }
    },
    {
        path: '/root/tenant/:tenantId/manageControlFrameWork',
        name: 'tenantControls',
        meta: {
            title: 'tenant-controls',
            layout: adminLayout,
        },
        component: tenantControls,
        // beforeEnter: (to, from, next) => {
        //     admiNavigationGuard(to, from, next)
        // }
    },
    // {
    //     path: '/root/framework/:frameworkId/kpiRootControlMapping',
    //     name: 'kpiRootControlMapping',
    //     meta: {
    //       title: 'kpiControlMap',
    //       layout: adminLayout,
    //     },
    //     component: kpiRootControlMap,
    //     // beforeEnter: (to, from, next) => {
    //     //     controlsNavigationGuards(to, from, next)
    //     // }
    // },
    {
        path: '/root/framework/:id/manageRootControlMapping',
        name: 'manageRootControlMapping',
        meta: {
            title: 'KPIS',
            layout: adminLayout,
        },
        component: manageRootControlMap,
        // beforeEnter: (to, from, next) => {
        //     kpisNavigationGuards(to, from, next)
        // }
    },
 ]
 const routes: Array<RouteRecordRaw> = [
    // {
    //     path: '*',
    //     name: 'Page not found',
    //     meta: {
    //         title: 'error',
    //         layout: 'empty-layout',
    //     },
    //     props: { errorResult: { Number: 404, Description: 'asfsa' } },
    //     component: errorPage
    // },
    {
        path: '/error',
        name: 'error',
        meta: {
            title: 'error',
           
        },
        component: errorPage
    },
    {
        path: '/',
        name: 'default',
        meta: {
            title: "Dashboard",
            layout: defaultLayout,
        },
        redirect: { name: 'dashboard' }
    },
    {
        path: '/admin',
        name: 'default-admin',
        meta: {
            title: "Dashboard",
            layout: defaultLayout,
        },
        redirect: { name: 'root-controls' }
    },
    // {
    //     path: '/',
    //     meta: {
    //         title: "Dashboard",
    //         layout: defaultLayout,
    //     },
    //     redirect: { name: 'frameworks' }
    // },
    // {
    //     path: '/admin',
    //     meta: {
    //         title: "Dashboard",
    //         layout: defaultLayout,
    //     },
    //     redirect: { name: 'root-controls' }
    // },

    ...controlRoutes,
    ...kpiRoutes,
    ...tasksRoutes,
    ...dahsboardRoutes,
    ...adminRoutes
];

const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.beforeEach(async (to, from, next) => {
    store.state.isLoading = true;
    if(to.meta.title){
        document.title=`Monitor - ${to.meta.title}`
    }
    else{
        document.title=`Monitor`
    }
    console.log('Router check triggered', to)
    if (to.path == '/admin' || to.path=='/' || to.path=='/org/dashboard' || to.path=='/org/frameworks' ||to.path == '/root/allControls') {
        console.log('Nav to default route with', to.query.session)
        if (to.query.session) {
            localStorage.setItem('AUTH_ID', to.query.session.toString());
            await store.dispatch('getUserInfo').then((res) => {
                console.log('response', res.data[0]);
                if (!res.data[0]) window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_MONITOR_WEBAPP_URL}`;
                else {
                    next();
                }
            })
        }
        await normalAuthenticationCheck(next);
    } else {
        await normalAuthenticationCheck(next);
    }
    // next();
});

router.afterEach(async (to, from, next) => {
    store.state.isLoading = false
});


async function normalAuthenticationCheck(next: any) {
    console.log('Inside normal check')
    if(!localStorage.getItem('AUTH_ID')) {
        console.log('AUTH ID NOT found')
        window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_MONITOR_WEBAPP_URL}`;
    } else{
        const userInfo = store.getters.userInfo;
        console.log('USER INFO', userInfo)
        if(!userInfo.roles) {
            await store.dispatch('getUserInfo').then((res) => {
                next();
            });
        }
        else{
            next();
        }
    }
}




export default router;