<template>
	<div class="pt-3 pl-5 pr-5">
		<div v-if="isLoading">
			<div class="row w-full justify-between flex" style="align-items: center">
				<h1 class="title">Tasks</h1>
				<div class="cardbody">
					<div class="flex flex-row-reverse items-center">
						<div class="flex flex-row-reverse items-center">
							<button class="filterButton flex" @click="filterOpen" v-if="tableRow.length > 0">
								Filters
								<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
							</button>
						</div>
					</div>
				</div>
			</div>
			<tableData :tableRowData="tableRow" :headersData="headers"></tableData>
		</div>
		<!-- <loader v-if="showLoader" /> -->
	</div>
</template>			


<script lang="ts">
import _ from 'lodash';
// import loader from '../../components/loader.vue';
import { defineComponent } from 'vue';
import { emitter, toast } from '@/main';
import tableData from '@/components/tableData.vue';
export default defineComponent({
	data(): any {
		return {
			headers:['key','ID','Issue Name','Issue Type','Status','Department','Created on'],

			isLoading: false,
			showLoader: false,

			showFilters: false,

			departmentsList: [],
			tableRow: []
		};
	},

	components: {
		tableData,
		// loader
	},
	async created() {
		await this.fetchAllTasks();
		await this.fetchDepartmentsList();
	},
	methods: {
		fetchDepartmentsList() {
			this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
				this.departmentsList = res.data;
			});
		},
		async fetchAllTasks() {
			// (this.showLoader = true), (this.isLoading = false);
			// this.$http
			// 	.get(`http://localhost:3040/services/${process.env.VUE_APP_MONITOR_API_PATH}/api/org/tasks`)
			// 	.then((result:any) => {
			// 		this.isLoading = true;
			// 		(this.showLoader = false);
			// 		this.tableBody = result.data;
			// 	})
			// 	.catch((error:any) => {
			// 		this.isLoading = false;
			// 		(this.showLoader = false);
			// 			this.$toasted.error(error, {
			// 				theme: 'toasted-primary',
			// 				duration: 1000
			// 			});
			// 	});
			this.isLoading = true;
			this.showLoader = false;
			// this.tableRow.forEach((item:any)=>{
			// 	this.tableRow.push({key:10001,ID : item._id, 'Issue Name':item.issueName, 'Issue Type':item.issueType, Status:item.status, Department:item.department,'Created on':item.createdOn})
			// })
			this.tableRow = [
				{ key: 10001, ID: 0, 'Issue Name': 'IssueNameOne', 'Issue Type': 'IssueTypeOne', Status: 'Closed', Department: 'IT', 'Created on': '01/08/2023' },
				{ key: 10001, ID: 1, 'Issue Name': 'IssueNameOne', 'Issue Type': 'IssueTypeOne', Status: 'Open', Department: 'Warehouse', 'Created on': '01/08/2023' },
				{ key: 10001, ID: 2, 'Issue Name': 'IssueNameOne', 'Issue Type': 'IssueTypeOne', Status: 'Open', Department: 'HR', 'Created on': '02/08/2023' },
				{ key: 10001, ID: 3, 'Issue Name': 'IssueNameOne', 'Issue Type': 'IssueTypeOne', Status: 'Open', Department: 'Marketing', 'Created on': '03/08/2023' },
				{ key: 10001, ID: 4, 'Issue Name': 'IssueNameOne', 'Issue Type': 'IssueTypeOne', Status: 'Closed', Department: 'Sales', 'Created on': '04/08/2023' },
				{ key: 10001, ID: 5, 'Issue Name': 'IssueNameOne', 'Issue Type': 'IssueTypeOne', Status: 'Closed', Department: 'IT', 'Created on': '05/08/2023' }
			];
		},
		updateTask() {
			if (this.clickedTask.metrics.length > 0) {
				let metricValues: any = [];
				this.clickedTask.metrics.forEach((element: any) => {
					metricValues.push(element.value);
				});
				this.clickedTask.total = parseInt(metricValues[0]);
				this.clickedTask.actual = parseInt(metricValues[1]);
			} else {
				let metricValues: any = [];
				this.kpimetrics.forEach((element: any) => {
					metricValues.push(element.value);
				});
				this.clickedTask.total = parseInt(metricValues[0]);
				this.clickedTask.actual = parseInt(metricValues[1]);
			}

			if (this.clickedTask.taskStatus == 'CLOSE') {
				// this.clickedTask.kpiEffectiveness = parseInt((this.clickedTask.total / this.clickedTask.actual) * 100);
			}

			this.$http.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_MONITOR_API_PATH}/api/org/task/${this.clickedTask._id}`, this.clickedTask).then(() => {
				toast.success(`Saved`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				this.tableBody = [];
				this.tableHeaders = [];
				this.showPopup = false;
				this.fetchAllTasks();
				// this.$refs['taskDialog']).modal('hide');
			});
		},
		filterOpen() {
			this.showFilters = !this.showFilters;
			emitter.emit('visible', this.showFilters);
		}
	}
});
</script>